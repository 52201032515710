<div mat-dialog-title class="scdl-dialog-title" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="" class="scdl-dialog-titleContainer">
    <h2 fxFlex="">Lista de agendamentos</h2>
    <button mat-icon-button aria-label="Fechar lista" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content fxLayout="row wrap" class="mat-typography">
  <div fxFlex="100" fxLayout="row wrap" class="scdl-crud-filter">
    <div fxFlex class="scdl-sessions-data" fxLayout="column" fxLayoutAlign="center start">
      <p><label class="scdl-label-titles">{{eventData.name}}</label> </p>
      <p><label class="scdl-label-subtitles">O evento ocorrerá em {{eventData.startDate | date : 'dd/MM/yyyy'}} às {{eventData.hourAfterOpening}}, com abertura às {{eventData.hourWatchOpening}}.</label> </p>
      <p>{{eventData.description}}</p>
    </div>
  </div>
  <div fxFlex="100" fxLayout="row wrap" class="scdl-crud-filter">
    <div fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="40" fxFlex.gt-md="30">
      <mat-form-field cdkFocusInitial fxFlex="100" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Pesquisar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Pesquise por nome, telefone ou e-mail..." #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex.xs="100"  fxFlex="" class="scdl-padding-8x8"></div>
    <div fxFlex.xs="100"  fxLayoutAlign="center start" class="scdl-crud-filter-box-add">
      <button mat-stroked-button color="primary" (click)="openSchedulingRegisterDialog()">
        <mat-icon fxLayout="row" fxLayoutAlign="center center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-plus" viewBox="0 0 16 16">
            <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
          </svg>
        </mat-icon>
        Novo agendamento
      </button>
    </div>
  </div>
  <div fxFlex="100" class="scdl-crud-container">
    <table fxFlex="100" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Posição</th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>

      <ng-container matColumnDef="securityCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de segurança </th>
        <td mat-cell *matCellDef="let element"> {{element.securityCode}} </td>
      </ng-container>

      <ng-container matColumnDef="cellPhone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefone </th>
        <td mat-cell *matCellDef="let element"> {{element.cellPhone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="observation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Observação </th>
        <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow class="scdl-crud-no-match">
        <td class="mat-cell" colspan="6">Nenhum agendamento encontrado</td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" class="scdl-crud-filter">
    <div fxFlex=""></div>
    <mat-paginator [pageSizeOptions]="[50, 100, 125, 150, 200]" aria-label="Selecione uma página"></mat-paginator>
  </div>
</mat-dialog-actions>
<!-- <mat-dialog-actions align="end">
  <button mat-stroked-button
  color="primary"
  [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>
  <button mat-raised-button
  color="primary"
  [mat-dialog-close]="true" >Continuar</button>
</mat-dialog-actions> -->
