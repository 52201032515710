import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // @ViewChild('map') mapElement: any;
  // map: google.maps.Map;
  // private allLinks: any;

  ngOnInit() {
    // map: google.maps.Map;
  }

  // initMenuLinks() {
  //   this.allLinks = document.querySelectorAll('footer ul li a');
  //   for (const link of this.allLinks) {
  //     link.addEventListener('click', this.clickHandler);
  //   }
  // }

  // public clickHandler(e: any) {
  //   e.preventDefault();
  //   const anchor = e.target.closest("a");
  //   if (!anchor) return;
  //   let href = anchor.getAttribute('href');
  //   const elm =  document.querySelector('#' + href) as HTMLElement;
  //   if(!elm) return ;
  //   scroll({
  //     top: elm.offsetTop - 100,
  //     behavior: "smooth"
  //   })
  // }

}
