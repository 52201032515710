<div class="scdl-admin-layout">
  <div class="scdl-admin-box-titles" fxLayout="row">
    <h2 class="scdl-admin-titles scdl-light-gray-color">Eventos</h2>
  </div>
  <div class="scdl-admin-box-container" fxLayout="row wrap" class="mat-elevation-z8">
    <div fxFlex="100" fxLayout="row wrap" class="scdl-crud-filter">
      <div fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-sm="40" fxFlex.gt-md="30">
        <mat-form-field fxFlex="100" appearance="outline" subscriptSizing="dynamic">
          <mat-label>Pesquisar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Pesquise por nome..." #input>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex.xs="100"  fxFlex="" class="scdl-padding-8x8"></div>
      <div fxFlex.xs="100"  fxLayoutAlign="center start" class="scdl-crud-filter-box-add">
        <button mat-stroked-button color="primary" (click)="goToEventRegisterPage()">
          <mat-icon fxLayout="row" fxLayoutAlign="center center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard2-plus" viewBox="0 0 16 16">
              <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z"/>
              <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z"/>
              <path d="M8.5 6.5a.5.5 0 0 0-1 0V8H6a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V9H10a.5.5 0 0 0 0-1H8.5V6.5Z"/>
            </svg>
          </mat-icon>
          Novo evento
        </button>
      </div>
    </div>
    <div fxFlex="100" class="scdl-crud-container">
      <table fxFlex="100" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data do evento</th>
          <td mat-cell *matCellDef="let element"> {{element.startDate | date : 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="fullCapacity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Capacidade total </th>
          <td mat-cell *matCellDef="let element"> {{element.fullCapacity}} </td>
        </ng-container>

        <ng-container matColumnDef="openingCapacity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Vagas para a abertura </th>
          <td mat-cell *matCellDef="let element"> {{element.openingCapacity}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status.description}} </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef> Ações </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações"
            (click)="setselectedEvent(element)">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow class="scdl-crud-no-match">
          <td class="mat-cell" colspan="6">Nenhum registro encontrado</td>
        </tr>
      </table>
    </div>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" class="scdl-crud-filter">
      <div fxFlex=""></div>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Selecione uma página"></mat-paginator>
    </div>
  </div>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="editEvent()">Editar
    <mat-icon fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
    </svg></mat-icon>
  </button>
  <button mat-menu-item *ngIf="selectedEvent?.status?.id==='AVAILABLE_SCHEDULING' || selectedEvent?.status?.id === 'SCHEDULED' || selectedEvent?.status?.id === 'ATIVED'"
  (click)="updateEventStatus()">
  {{(selectedEvent.status.id === 'ATIVED' || selectedEvent.status.id === 'AVAILABLE_SCHEDULING') ? 'Agendar' : 'Disponibilizar agendamento'}}
    <mat-icon *ngIf="selectedEvent?.status?.id === 'AVAILABLE_SCHEDULING' || selectedEvent?.status?.id === 'ATIVED'"
    fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
      <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
    </svg></mat-icon>
    <mat-icon *ngIf="selectedEvent?.status?.id === 'SCHEDULED'" fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
      <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
    </svg></mat-icon>
  </button>
  <button mat-menu-item (click)="confirmDeleteEvent()">Excluir
    <mat-icon fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
    </svg></mat-icon>
  </button>
  <button mat-menu-item (click)="getScheduledClients()">Listar agendamentos
    <mat-icon fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
      <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
    </svg></mat-icon>
  </button>
</mat-menu>
