<div mat-dialog-title class="scdl-dialog-title" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="row" fxFlex="" class="scdl-dialog-titleContainer">
    <h2 fxFlex="">{{dialogMessages.mainTitleDialog}}</h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <p>{{dialogMessages.messageDialog}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button
  color="primary"
  [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>
  <button mat-raised-button
  color="primary"
  [mat-dialog-close]="true" >Continuar</button>
</mat-dialog-actions>
