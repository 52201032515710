<div class="scdl-admin-layout" fxLayout="row" fxLayoutAlign="center start">
  <div fxFlex.gt-md="65" fxFlex.gt-sm="70" fxFlex.gt-xs="80" fxFlex="100">
    <div class="scdl-admin-box-titles with-back-button" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button color="primary" (click)="goToUsersCrudPage()"
      aria-label="Voltar" title="voltar">
        <mat-icon fxLayout="row" fxLayoutAlign="center center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
        </mat-icon>
      </button>
      <h2 class="scdl-admin-titles scdl-light-gray-color"> {{userData?.id ? 'Editar usuário': 'Cadastrar de usuário'}}</h2>
    </div>
    <div class="scdl-admin-box-container scdl-padding-20x20 mat-elevation-z8 scdl-white-box" fxLayout="row wrap">
      <form [formGroup]="userForm"  (ngSubmit)="saveUserData()"
        fxFlex="100">
        <div fxLayout="row wrap" fxLayoutGap.gt-md="20px">
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Nome</mat-label>
              <input matInput required formControlName="username"
                  [(ngModel)]="userData.username" name="username" type="text">
                  <mat-error>Informe o nome do usuario</mat-error>
              <mat-hint>Informe o nome do usuário</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>E-mail</mat-label>
              <input matInput [pattern]="emailPattern" formControlName="email"
                [(ngModel)]="userData.email" name="email" type="email">
                <mat-hint>Informe o e-mail do usuário</mat-hint>
              <mat-error>E-mail inválido</mat-error>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Login</mat-label>
              <input matInput required formControlName="login"
                  [(ngModel)]="userData.login" name="login" type="text">
                  <mat-error>Informe o login do usuario</mat-error>
              <mat-hint>Informe o login do usuário</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap.gt-md="20px">
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Senha</mat-label>
              <input matInput [required]="!userData.id" formControlName="password"
                placeholder="{{userData.id ? '**********' : ''}}"
                [(ngModel)]="userData.password" name="password"
                type="{{showPass ? 'text': 'password'}}">
                <button mat-icon-button matSuffix (click)="$event.preventDefault(); showPassControl()">
                  <mat-icon *ngIf="showPass" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                    </svg>
                  </mat-icon>
                  <mat-icon *ngIf="!showPass" matSuffix>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                    </svg>
                  </mat-icon>
                </button>
              <mat-error>Preencha este campo</mat-error>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Confirmação de senha</mat-label>
              <input matInput [required]="!userData.id" formControlName="confirmPassword"
                placeholder="{{userData.id ? '**********' : ''}}"
                [(ngModel)]="userData.confirmPassword" name="confirmPassword"
                type="{{showPass ? 'text': 'password'}}">
                <button mat-icon-button matSuffix (click)="$event.preventDefault(); showPassControl()">
                  <mat-icon *ngIf="showPass" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                    </svg>
                  </mat-icon>
                  <mat-icon *ngIf="!showPass" matSuffix>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                    </svg>
                  </mat-icon>
                </button>
              <mat-error>{{userForm.controls['confirmPassword']['errors'] && userForm.controls['confirmPassword']['errors']['notEquivalent'] ? 'As senhas devem ser iguais' : 'Preencha este campo'}} </mat-error>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <div fxLayout="column">
              <mat-label>Perfil de usuário</mat-label>
              <mat-radio-group color="primary" formControlName="role" [(ngModel)]="userData.role" name="role"
              fxLayout="row">
              <mat-radio-button *ngFor="let status of roles"
              style="margin-right: 15px;"
              [value]="status.id">{{status.description}}</mat-radio-button>
                <!-- <span style="width: 15px;"></span>
                <mat-radio-button value="SUPERADMIN">SUPERADMIN</mat-radio-button> -->
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap.gt-md="20px">
          <div class="scdl-form-box" fxFlex.gt-md="" fxFlex="100">
            <div fxLayout="column">
              <mat-label>Status</mat-label>
              <mat-slide-toggle class="scdl-slide-toggle"
                formControlName="status" name="status"
                color="primary"
                [(ngModel)]="userData.status">
                {{userData.status ? 'Ativo' : 'Bloqueado'}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="scdl-container-box" fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button color="primary" [disabled]="userForm.invalid"
          type="submit">Salvar</button>
        </div>
      </form>
    </div>
  </div>
</div>
