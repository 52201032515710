<div fxLayout="row" class="feedbackMessagesContainer">
  <div fxFlex="">
    <p class="noticeTitle">{{titleNotice}}</p>
    <p class="noticeText">{{dataNotice}}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button aria-label="Fechar" (click)="close()"
    class="feedbackMessagesBtn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
