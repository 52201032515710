<div class="scdl-about-section" style="padding-top: 20px; padding-bottom: 20px;">
  <!-- <div class="decoration-border-before"></div> -->
  <div class="scdl-about-container" fxLayout="row" fxLayoutAlign="center center" >
    <div fxFlex="100" class="scdl-content-width" fxLayout="row wrap" fxLayoutAlign="center start">
      <div class="scdl-about-columns-container" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center start">
        <div class="scdl-about-box-img">
          <img src="/assets/img/about.jpg" alt="Quem Somos">
        </div>
      </div>
      <div class="scdl-about-columns-container" fxFlex="50" fxFlex.xs="100">
        <h3 class="scdl-default-titles"> Quem <span class="scdl-primary-color">somos</span></h3>
        <h2 class="scdl-default-titles">Conheça um pouco de quem somos</h2>
        <p>Guardiões da Luz Divina tem como inspiração a resiliência humana, na capacidade de superar adversidades e na incessante busca por equidade e dignidade. Cultivamos a resistência, o amor e a união, mantendo-nos firmes e fortes mesmo nas adversidades da vida.</p>
        <p>Nosso espaço é um refúgio de partilha e conexão, no qual buscamos promover o entrelaçamento das pessoas, compartilhando experiências de vida, proporcionando colaboração mútua e fortalecendo a importância da vivência coletiva.</p>
        <p>O objetivo é o desenvolvimento pessoal e comunitário, cientes de que ninguém cresce isoladamente. Nos embasamos em princípios espirituais e na ampla compreensão da existência. Aspiramos ser parte integral da caminhada de cada um, e, reciprocamente, desejamos aos que se unem a nós, integrem-se plenamente em nossa comunidade, fortalecendo os laços da unidade.</p>
        <p>Os Guardiões da Luz Divina, transcende o convencional. Temos como compromisso buscar nutrir os laços com os ancestrais e aprofundar em sua espiritualidade, oferecendo orientação e entendimento para os que buscam a compreensão na vivência plena da sua espiritualidade.</p>
      </div>
    </div>
  </div>
  <div class="decoration-border-after"></div>
</div>
