import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FeedbackMessagesComponent } from '../../../shared/components/feedback-messages/feedback-messages.component';
import { LoginService } from './services/login.service';
import { LoaderService } from '../../../shared/components/loader/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit {

  public showPass: boolean = false;
  public loginForm: FormGroup;
  public loginData: any= {
    login: '',
    password: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private loginService : LoginService ,
    private feedbackMessagesComponent : FeedbackMessagesComponent ,
    private router: Router,
    private loaderService : LoaderService,
    // private activatedRoute : ActivatedRoute ,
  ) {

    this.loginForm = this.formBuilder.group({
      login: new FormControl(this.loginData.login, [
        Validators.required,
        // Validators.minLength(3)
      ]),
      password: new FormControl(this.loginData.password, [
        Validators.required,
        // Validators.minLength(5)
      ]),
    });

  }

  ngOnInit() {
  }

  showPassControl() {
    this.showPass = !this.showPass
  }


  login() {
    this.loaderService.setChangesLoaderState(true);
    this.loginService.resolve(this.loginData).subscribe({
      next: (response) => {
        if(response && response.token) {
          localStorage.setItem('$scdlAdmToken', response.token);
          this.feedbackMessagesComponent.showMessageSuccess("Login efetuado com sucesso");
          this.router.navigate(['admin', 'atendimento']);
        }
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.loaderService.setChangesLoaderState(false);
        this.feedbackMessagesComponent.showMessageError("Dados incorretos. Confira-os")
      },
    });

    /*
      admin
      adminPassword

      superadmin
      superadmin
    */
  }
}
