import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog'
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrentEventsComponent } from './current-events/current-events.component';
import { StartEventsComponent } from './start-events/start-events.component';
import { StartScheduleQueueComponent } from './start-schedule-queue/start-schedule-queue.component';
import { UpdateScheduleStatusComponent } from './update-schedule-status/update-schedule-status.component';



@NgModule({
  declarations: [
    CurrentEventsComponent,
    StartEventsComponent,
    StartScheduleQueueComponent,
    UpdateScheduleStatusComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    FormsModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    MatTooltipModule,
  ]
})
export class SchedulingsModule { }
