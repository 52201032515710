<div class="scdl-admin-layout">
  <div class="scdl-admin-box-titles">
    <h2 class="scdl-admin-titles scdl-light-gray-color"><b>{{eventData.type?.description}}</b> | {{eventData.name}} </h2>
    <h3 class="scdl-admin-titles scdl-light-gray-color">Dia {{eventData.startDate | date : 'dd/MM/yyyy'}}</h3>
    <!-- <h5 class="scdl-admin-titles scdl-light-gray-color">{{eventData.description}}</h5> -->
  </div>
  <div class="scdl-admin-box-container" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100" fxLayout="row wrap" class="scdl-start-event-filter">
      <div fxFlex.xs="100" fxFlex fxLayoutAlign.xs="center center" fxLayoutAlign="start start">
        <mat-form-field fxFlex.xs="100" appearance="outline" subscriptSizing="dynamic" style="margin-bottom: 10px;">
          <mat-label>Pesquisar</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Pesquise por nome..." #input>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <span style="width: 20px;"></span>
      <div fxFlex.gt-md="20" fxFlex.gt-sm="30" fxFlex="45" fxFlex.xs="100" fxLayout="row wrap"
      fxLayoutAlign="center center" fxLayoutAlign.gt-md="center start"
      class="scdl-start-event-filter-actions">
        <button fxFlex="100" mat-raised-button color="primary"
        [disabled]="!eventData.customersWaiting?.length"
        (click)="startNextCall()">
          <mat-icon fxLayout="row" fxLayoutAlign="center center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
              <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
              <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
            </svg>
          </mat-icon>
          Chamar próximos
        </button>
        <button fxFlex="100" mat-stroked-button color="primary" (click)="openSchedulingRegisterDialog()">
          <mat-icon fxLayout="row" fxLayoutAlign="center center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-plus" viewBox="0 0 16 16">
              <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
            </svg>
          </mat-icon>
          Novo agendamento
        </button>
      </div>
    </div>
    <div>
      <h4 class="scdl-admin-titles scdl-light-gray-color" style="margin-bottom: 24px;">
        Total de {{totalSchedules && totalSchedules<10 ? '0' : ''}}{{totalSchedules}} {{totalSchedules == 1 ? 'pessoa agendada' : 'pessoas agendadas'}}
      </h4>
    </div>
    <mat-accordion fxFlex="100" class="scdl-accordion" multi>

      <!-- Aguardando -->
      <mat-expansion-panel class="scdl-expansion-panel" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{eventData.customersWaiting?.length && eventData.customersWaiting?.length<10 ? '0' : ''}}{{eventData.customersWaiting?.length}} AGENDAMENTOS AGUARDANDO
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="scdl-expansion-body">
          <div class="scdl-expansion-contents scdl-crud-container">
            <table fxFlex="100" mat-table [dataSource]="customersWaitingDataSource">
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Posição </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
              </ng-container>

              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
                <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
              </ng-container>

              <ng-container matColumnDef="securityCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de segurança </th>
                <td mat-cell *matCellDef="let element"> {{element.securityCode}} </td>
              </ng-container>

              <ng-container matColumnDef="cellPhone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefone</th>
                <td mat-cell *matCellDef="let element"> {{element.cellPhone}} </td>
              </ng-container>

              <ng-container matColumnDef="observation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Observação</th>
                <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
              </ng-container>

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações"
                  (click)="setselectedPerson(element, 1)">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow class="scdl-crud-no-match">
                <td class="mat-cell" colspan="6">Nenhum registro a ser exibido</td>
              </tr>
            </table>
          </div>
          <div class="scdl-expansion-contents">
            <div fxFlex=""></div>
            <mat-paginator #waitingPaginator
            [pageSizeOptions]="[10, 25, 50, 100]"
            aria-label="Selecione uma página"></mat-paginator>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Atendidos -->
      <mat-expansion-panel class="scdl-expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title class="scdl-served-list-title">
            {{eventData.customersServed?.length && eventData.customersServed?.length<10 ? '0' : ''}}{{eventData.customersServed?.length}} AGENDAMENTOS ATENDIDOS
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="scdl-expansion-body">
          <div class="scdl-expansion-contents scdl-crud-container">
            <table fxFlex="100" mat-table [dataSource]="customersServedDataSource">
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Posição </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
              </ng-container>

              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
                <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
              </ng-container>

              <ng-container matColumnDef="securityCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de segurança </th>
                <td mat-cell *matCellDef="let element"> {{element.securityCode}} </td>
              </ng-container>

              <ng-container matColumnDef="cellPhone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefone</th>
                <td mat-cell *matCellDef="let element"> {{element.cellPhone}} </td>
              </ng-container>

              <ng-container matColumnDef="observation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Observação</th>
                <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
              </ng-container>

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações"
                  (click)="setselectedPerson(element, 2)">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow class="scdl-crud-no-match">
                <td class="mat-cell" colspan="6">Nenhum registro a ser exibido</td>
              </tr>
            </table>
          </div>
          <div class="scdl-expansion-contents">
            <div fxFlex=""></div>
            <mat-paginator #servedPaginator
            [pageSizeOptions]="[10, 25, 50, 100]"
            aria-label="Selecione uma página"></mat-paginator>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Ausentes / Cancelados -->
      <mat-expansion-panel class="scdl-expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title class="scdl-not-served-list-title">
            {{eventData.customersNotServed?.length && eventData.customersNotServed?.length<10 ? '0' : ''}}{{eventData.customersNotServed?.length}} AGENDAMENTOS AUSENTES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="scdl-expansion-body">
          <div class="scdl-expansion-contents scdl-crud-container">
            <table fxFlex="100" mat-table [dataSource]="customersNotServedDataSource">
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Posição </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
              </ng-container>

              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome</th>
                <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
              </ng-container>

              <ng-container matColumnDef="securityCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de segurança </th>
                <td mat-cell *matCellDef="let element"> {{element.securityCode}} </td>
              </ng-container>

              <ng-container matColumnDef="cellPhone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefone</th>
                <td mat-cell *matCellDef="let element"> {{element.cellPhone}} </td>
              </ng-container>

              <ng-container matColumnDef="observation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Observação</th>
                <td mat-cell *matCellDef="let element"> {{element.observation}} </td>
              </ng-container>

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações"
                  (click)="setselectedPerson(element, 3)">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow class="scdl-crud-no-match">
                <td class="mat-cell" colspan="6">Nenhum registro a ser exibido</td>
              </tr>
            </table>
          </div>
          <div class="scdl-expansion-contents">
            <div fxFlex=""></div>
            <mat-paginator #notServedPaginator
            [pageSizeOptions]="[10, 25, 50, 100]"
            aria-label="Selecione uma página"></mat-paginator>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="tableId==1"
  (click)="startNextCall(selectedPerson.id)">Chamar pessoa
  </button>
  <button mat-menu-item *ngIf="tableId==2 || tableId==3"
  (click)="updatePersonStatus(selectedPerson.id)">Alterar status de atendimento
  </button>
</mat-menu>

