import { Component, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchedulingRegisterComponent } from '../scheduling-register/scheduling-register.component';
import { GetScheduledClientsService } from '../services/get-scheduled-clients.service';


export interface SchedulingInterface {
  id: string,
  fullName: string,
  cellPhone: string,
  email: string,
  observation: string,
  position: number,
  status: string,
  securityCode: string,
  // openingSession: boolean,
}

@Component({
  selector: 'app-schedulings-list-by-event',
  templateUrl: './schedulings-list-by-event.component.html',
  styleUrls: ['./schedulings-list-by-event.component.scss']
})

export class SchedulingsListByEventComponent   implements AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) schedulePaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  eventData: any = {};
  schedulingList: SchedulingInterface[] = [];
  dataSource: MatTableDataSource<SchedulingInterface>;


  displayedColumns: string[] = ['fullName', 'position', 'securityCode','cellPhone', 'email', 'observation'];

  constructor(
    private dialogRef: MatDialogRef<SchedulingsListByEventComponent>,
    private confirmActionDialog : MatDialog ,
    private getScheduledClientsService : GetScheduledClientsService ,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {
  }

  ngOnInit() {
    this.eventData = this.dataDialog.eventData;
    this.schedulingList = this.dataDialog.schedulings;
    this.dataSource = new MatTableDataSource(this.schedulingList);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.schedulePaginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();
    this.dataSource.filter = filterValue.trim().normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  editScheduling() {
    this.openSchedulingRegisterDialog();
  }

  openSchedulingRegisterDialog(schedulingData: any = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = true;
    dialogConfig.maxWidth = '680px';
    dialogConfig.width = '95vw';
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.data = {
      schedulingData: schedulingData,
      eventData: this.eventData,
    };
    const schedulingRegisterComponentRef = this.confirmActionDialog.open(SchedulingRegisterComponent, dialogConfig);
    schedulingRegisterComponentRef.beforeClosed().subscribe(
      response => {
        if(response && response.id) {
          this.getScheduledClients();
        }
      }
    );
  }

  getScheduledClients(){
    const data = {
      id: this.eventData.id,
    }
    this.getScheduledClientsService.resolve2(data).subscribe({
      next: (response) => {
        if(response && response.length) {
          this.updateListData(response);
        }
      },
      error: (error) => {
      },
    })
  }

  updateListData(schedulingList: any[]) {
    this.schedulingList = schedulingList;
    this.dataSource.data = this.schedulingList;
  }

}
