import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute} from '@angular/router';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';

@Component({
  selector: 'app-current-events',
  templateUrl: './current-events.component.html',
  styleUrls: ['./current-events.component.scss']
})
export class CurrentEventsComponent implements OnInit{

  @ViewChild(MatAccordion) accordion: MatAccordion;

  public currentEvents: any[] = [];

  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    public feedbackMessagesComponent: FeedbackMessagesComponent,
  ) {
    this.buildEventData();
  }

  buildEventData() {
    this.currentEvents = this.activatedRoute.snapshot.data['getCurrentEventsService'] ? this.activatedRoute.snapshot.data['getCurrentEventsService'] : {};
  }

  ngOnInit() {

  }

  goToStartEvent(event: any) {
    this.router.navigate(['admin/iniciar-atendimento/'+event.id]);
  }

}
