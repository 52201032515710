import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PlatformLocation } from '@angular/common';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';


export interface HttpResponse {
  status: string;
  data: any;
  success: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly API = `${environment.API}`;

  private readonly options = {
    authorization: '', //localStorage.getItem('');
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: authorization ? 'Bearer ' + authorization : '',
    })
  };

  constructor(
    private httpClient: HttpClient,
    private platformLocation: PlatformLocation,
  ) { }

  get(paramns: any): Observable<HttpResponse> {
    return this.httpClient.get(this.API + paramns.url, this.options).pipe(tap((response: any) => response));
  }

  post(paramns: any): Observable<HttpResponse>  {
    return this.httpClient.post<any>(this.API + paramns.url, paramns.data, this.options);
  }

  errorHandler(httpErrorResponse: HttpErrorResponse) {
    return httpErrorResponse.error;
  }


}
