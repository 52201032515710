import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'

// import { GoogleMapsModule } from '@angular/google-maps'
import { ClientRootComponent } from './client-root/client-root.component';
import { ClientMainBarComponent } from '../../shared/components/client-main-bar/client-main-bar.component';
import { ScdlFeedbackMessagesComponent } from '../../shared/components/feedback-messages/feedback-messages.component';
import { HomeComponent } from './home/home.component';
import { EventDatailsComponent } from './event-datails/event-datails.component';
import { BannerComponent } from './home/sessions/banner/banner.component';
import { EventsComponent } from './home/sessions/events/events.component';
import { AboutComponent } from './home/sessions/about/about.component';
import { MoralValuesComponent } from './home/sessions/moral-values/moral-values.component';
import { MissionComponent } from './home/sessions/mission/mission.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { DetailsComponent } from './event-datails/sessions/details/details.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    ClientRootComponent,
    ClientMainBarComponent,
    ScdlFeedbackMessagesComponent,
    HomeComponent,
    EventDatailsComponent,
    BannerComponent,
    EventsComponent,
    AboutComponent,
    MoralValuesComponent,
    MissionComponent,
    FooterComponent,
    DetailsComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    CdkAccordionModule,
    MatExpansionModule,
    NgxMaskDirective, NgxMaskPipe
    // GoogleMapsModule,
  ],
  providers: [provideNgxMask()]
})
export class ClientModule { }
