import { Component, OnInit, AfterViewInit, HostListener} from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

@Component({
  selector: 'app-client-main-bar',
  templateUrl: './client-main-bar.component.html',
  styleUrls: ['./client-main-bar.component.scss']
})
export class ClientMainBarComponent implements OnInit, AfterViewInit {


  @HostListener('window:scroll') onWindowScroll() {
    this.expandedNavbar = window.scrollY > 70 ? true : false;
  }

  public openedMenu: boolean = false;
  public expandedNavbar: boolean = false;
  public isHomePage: boolean = false;
  public logoImg: string = '';
  private allLinks: any;

  constructor (
    private router: Router,
  ) {
    this.isHomePage = this.router.url === '/home' || this.router.url.match('/home#') ? true : false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initMenuLinks()
  }

  initMenuLinks() {
    this.allLinks = document.querySelectorAll('nav #anchorMenu a');
    for (const link of this.allLinks) {
      link.addEventListener('click', this.clickHandler);
    }
  }


  public clickHandler(e: any) {
    e.preventDefault();
    const anchor = e.target.closest("a");
    if (!anchor) return;
    let href = anchor.getAttribute('href');
    const elm =  document.querySelector('#' + href) as HTMLElement;
    if(!elm) return ;
    scroll({
      top: elm.offsetTop - 100,
      behavior: "smooth"
    })
  }

  menuControl() {
    this.openedMenu = this.openedMenu ? false : true;
  }

  closeMenu() {
    this.openedMenu = false;
  }

  public gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
