import { Component } from '@angular/core';

@Component({
  selector: 'app-event-datails',
  templateUrl: './event-datails.component.html',
  styleUrls: ['./event-datails.component.scss']
})
export class EventDatailsComponent {

}
