import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS} from '@angular/material/core';


import { AppComponent } from './app.component';
import { ClientModule } from './modules/client/client.module';
import { AdminRootComponent} from './modules/admin/admin-root/admin-root.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { MainBarComponent } from './shared/components/main-bar/main-bar.component';
import { LoginComponent } from './modules/admin/login/login.component';
import { UsersCrudComponent } from './modules/admin/users/users-crud/users-crud.component';
import { UserRegisterComponent } from './modules/admin/users/user-register/user-register.component';
import { getDutchPaginatorIntl } from './shared/services/translate-paginator-intl';
import { ConfirmDeleteRegisterDialogComponent } from './shared/components/confirm-delete-register-dialog/confirm-delete-register-dialog.component';


import { EventsModule } from './modules/admin/events/events.module';
import { SchedulingsModule } from './modules/admin/schedulings/schedulings.module';

registerLocaleData(localePt);


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    MainBarComponent,
    AdminRootComponent,
    LoginComponent,
    UsersCrudComponent,
    UserRegisterComponent,
    ConfirmDeleteRegisterDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ClientModule,
    EventsModule,
    SchedulingsModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
