import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public loaderControl = new Subject<any>();

  constructor() { }


  getChangesLoaderState(): Observable<any> {
    return this.loaderControl.asObservable();
  }

  setChangesLoaderState(value: any) {
    this.loaderControl.next(value);
  }
}
