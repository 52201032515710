import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-bar',
  templateUrl: './main-bar.component.html',
  styleUrls: ['./main-bar.component.scss']
})
export class MainBarComponent {

  protected userData: any = {};
  protected menuOptions: any = [];

  constructor(
    private activatedRoute : ActivatedRoute ,
  ) {
    this.userData = this.activatedRoute.snapshot.data['getLoggedUserDataService'];
    this.buildMenuOptions();
  }

  buildMenuOptions() {
    if(this.userData?.hasSuper) {
      this.menuOptions = [
        {id: 1, title: 'Atendimento', link: '/admin/atendimento', actived: 0},
        {id: 1, title: 'Eventos', link: '/admin/eventos', actived: 0},
        {id: 2, title: 'Usuários', link: '/admin/usuarios', actived: 0},
        {id: 3, title: 'Sair', link: '/admin/login', actived: 0, logoutButton: true},
      ];
    } else {
      this.menuOptions = [
        {id: 1, title: 'Atendimento', link: '/admin/atendimento', actived: 0},
        {id: 3, title: 'Sair', link: '/admin/login', actived: 0, logoutButton: true},
      ];
    }
  }

  btnAction(isLogoutButton: boolean) {
    if(isLogoutButton) {
      localStorage.removeItem('$scdlAdmToken');
      window.location.reload();
    }
  }

}
