<header class="scdl-client-header" fxLayout="row" fxLayoutAlign="center center">
  <div id="scdl-banner" class="scdl-content-width scdl-margin-top-86" fxFlex="95" fxFlex.sm="80" fxFlex.xs="100" fxFlex.gt-md="">
    <div fxLayout="row" fxFlex="65" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-md="53" fxLayoutAlign="start end">
      <h1 class="home-title">
        "Quem nunca passou por uma encruzilhada não sabe escolher caminhos."
        <small>Antônio Bispo dos Santos (Nego Bispo)</small>
    </h1>
    </div>
  </div>
</header>
