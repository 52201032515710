import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { MakeSchedulingService } from '../services/make-scheduling.service';
import { LoaderService } from '../../../../shared/components/loader/loader.service';

@Component({
  selector: 'app-scheduling-register',
  templateUrl: './scheduling-register.component.html',
  styleUrls: ['./scheduling-register.component.scss']
})
export class SchedulingRegisterComponent implements OnInit {

  public registerForm: FormGroup;

  public namePattern = '^[A-Za-z]*.* [a-zA-ZÀ-ú]{2,50}[ ]*$';
  public emailPattern = '^(([0-9a-zA-Z]+[-._+&])*[-.+&0-9a-zA-Z_]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}){0,1}$';
  public phonePattern = '[\(]?[0-9]{2}[\)]?\ \?[0-9]{8,9}';
  public eventIsStarted: boolean = false;
  public eventData: any = {};
  public registerData: any = {
    fullName: '',
    email: '',
    cellPhone: '',
    // priority: false,
    observation: '',
  }

  constructor(
    private dialogRef: MatDialogRef<SchedulingRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private formBuilder: FormBuilder,
    private feedbackMessagesComponent : FeedbackMessagesComponent ,
    private makeSchedulingService: MakeSchedulingService,
    private loaderService : LoaderService,
  ) {
    if(dataDialog.registerData) {
      this.registerData = dataDialog.schedulingData;
    }
    if(dataDialog.eventIsStarted) {
      this.eventIsStarted = dataDialog.eventIsStarted;
    }
    this.eventData = dataDialog.eventData;

    this.registerForm = this.formBuilder.group({
      fullName: new FormControl(this.registerData.fullName, [
        Validators.required,
        Validators.minLength(3)
      ]),
      email: new FormControl(this.registerData.email, [
        Validators.email,
        // Validators.required,
        Validators.minLength(5)
      ]),
      cellPhone: new FormControl(this.registerData.cellPhone,
        this.eventIsStarted ?
      [
        Validators.pattern(/^[(]?[0-9]{2}[\)]?\s?\d{4,5}-?\d{4}$/)
      ] :
      [
        Validators.required,
        Validators.pattern(/^[(]?[0-9]{2}[\)]?\s?\d{4,5}-?\d{4}$/)
      ]
      ),
      // priority: new FormControl(this.registerData.priority, [
      // ]),
      observation: new FormControl(this.registerData.observation, [
      ]),
    });
  }

  ngOnInit() {
  }

  saveScheduling() {
    let sendData = JSON.parse(JSON.stringify(this.registerData));
    sendData.idEvent = this.eventData.id;
    this.loaderService.setChangesLoaderState(true);
    this.makeSchedulingService.resolve(sendData).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.dialogRef.close(response);
          this.feedbackMessagesComponent.showMessageSuccess('Agendamento realizado com sucesso.')
        }
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }

}
