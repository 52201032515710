import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { SaveUserService } from '../services/save-user.service';
import { EditUserService } from '../services/edit-user.service';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { LoaderService } from '../../../../shared/components/loader/loader.service';
@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit{

  public userForm: FormGroup;
  public userData: any = {
    id: '',
    username: '',
    email: '',
    login: '',
    password: '',
    confirmPassword: '',
    role: '',
    status: '',
  }
  public roles: any[] = [];
  public showPass: boolean = false;

  public emailPattern = '^(([0-9a-zA-Z]+[-._+&])*[-.+&0-9a-zA-Z_]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}){0,1}$';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    private saveUserService : SaveUserService ,
    private editUserService : EditUserService ,
    public feedbackMessagesComponent: FeedbackMessagesComponent,
    private loaderService : LoaderService,
    // private makeSchedulingService : MakeSchedulingService ,
  ) {
    this.buildUserData();
    this.buildForm();
  }

  buildUserData() {
    this.userData = this.activatedRoute.snapshot.data['getUserDataService'] ? this.activatedRoute.snapshot.data['getUserDataService'] : {role: 'ADMIN'};
    this.roles = this.activatedRoute.snapshot.data['getRolesService'] ? this.activatedRoute.snapshot.data['getRolesService'] : new Array();
    this.userData.status = this.userData.blocked ? false : true;
  }

  buildForm() {
    this.userForm = this.formBuilder.group({
      username: new FormControl(this.userData.username, [
        Validators.required,
        Validators.minLength(3)
      ]),
      email: new FormControl(this.userData.email, [
        Validators.email,
        Validators.required,
        Validators.minLength(5)
      ]),
      login: new FormControl(this.userData.login, [
        Validators.required,
        Validators.minLength(3)
      ]),
      password: new FormControl(this.userData.password, this.rulesPass()),
      confirmPassword: new FormControl(this.userData.confirmPassword, this.rulesPass()),
      role: new FormControl(this.userData.role, [
        Validators.required,
      ]),
      status: new FormControl(this.userData.status, []),
    },
    {
      validator: this.userData && !this.userData.id ? this.checkIfMatchingPasswords('password', 'confirmPassword') : ''
    });
  }

  rulesPass() {
    if(this.userData && this.userData.id) {
      return [
        Validators.minLength(3)
      ]
    }
    return [Validators.required, Validators.minLength(3)]
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      }
      else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  ngOnInit() {

  }

  saveUserData() {
    let sendData = JSON.parse(JSON.stringify(this.userData));
    sendData.blocked = sendData.status ? false : true;
    sendData.active = true;
    delete sendData.status;
    let url = this.userData.id ? 'user/update' : 'user/register';
    this.loaderService.setChangesLoaderState(true);
    if(this.userData?.id) {
      this.editUserService.resolve(sendData).subscribe({
        next: (response) => {
          this.responseSaveUserData(response);
          this.loaderService.setChangesLoaderState(false);
        },
        error: (error) => {
          this.feedbackMessagesComponent.showMessageError(error.description);
          this.loaderService.setChangesLoaderState(false);
        },
      })
    } else {
      this.saveUserService.resolve(sendData).subscribe({
        next: (response) => {
          this.responseSaveUserData(response);
          this.loaderService.setChangesLoaderState(false);
        },
        error: (error) => {
          this.feedbackMessagesComponent.showMessageError(error.description);
          this.loaderService.setChangesLoaderState(false);
        },
      })
    }
  }

  responseSaveUserData(response: any) {
    if(response && response.id) {
      this.userData.id = response.id;
      this.feedbackMessagesComponent.showMessageSuccess("Usuário salvo com sucesso");
    }
  }

  showPassControl() {
    this.showPass = !this.showPass
  }

  goToUsersCrudPage(){
    this.router.navigate(['admin/usuarios']);
  }
}
