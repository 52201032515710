import { Component } from '@angular/core';

@Component({
  selector: 'app-moral-values',
  templateUrl: './moral-values.component.html',
  styleUrls: ['./moral-values.component.scss']
})
export class MoralValuesComponent {

public moralValuesList: any = [
  {title: "Titulo 1", text: "Entendemos nossa comunidade como uma grande família, acolhendo cada membro com amor e valorizando sua singularidade."},
  {title: "Titulo 2", text: "Acreditamos na força do coletivo e na construção de um ambiente harmonioso e colaborativo."},
  {title: "Titulo 3", text: "O amor e o respeito orientam todas as nossas ações e decisões, e temos um compromisso firme com a promoção e defesa de temas sociais."},
  {title: "Titulo 4", text: "Lutamos ativamente contra o racismo, sempre buscando promover a igualdade racial."},
  {title: "Titulo 5", text: "Defendemos os direitos das mulheres e nos posicionamos contra qualquer forma de discriminação ou violência de gênero."},
  {title: "Titulo 6", text: "Nos opomos à xenofobia, pois acreditamos em um mundo onde as fronteiras não definem o valor humanos."},
  {title: "Titulo 7", text: "Em todas as nossas ações, buscamos a igualdade, pois entendemos que cada ser merece ser tratado com justiça e dignidade."},
  {title: "Titulo 8", text: "Nosso desejo é construir algo realmente valoroso para posteridade."},
];


}
