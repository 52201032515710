<div class="scdl-admin-layout">
  <div class="scdl-admin-box-titles" fxLayout="row">
    <h2 class="scdl-admin-titles scdl-light-gray-color">Usuários</h2>
  </div>
  <div class="scdl-admin-box-container" fxLayout="row wrap" class="mat-elevation-z8">
    <div fxFlex="100" fxLayout="row wrap" class="scdl-crud-filter">
      <div fxFlex.xs="100" fxFlex.gt-xs="100" fxFlex.gt-sm="40" fxFlex.gt-md="30">
        <mat-form-field fxFlex="100" appearance="outline" subscriptSizing="dynamic">
          <mat-label>Pesquisar</mat-label>
          <!-- <input matInput (keyup)="applyFilter($event)" placeholder="Pesquise por nome, e-mail ou login..." #input -->
          <input matInput (keyup)="applyFilter()" placeholder="Pesquise por nome, e-mail ou login..." #input
          [(ngModel)]="searchBar">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="" class="scdl-padding-8x8"></div>
      <div fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign.xs="center start" fxLayoutAlign="end start" class="scdl-crud-filter-box-add">
        <button mat-stroked-button [color]="deletedUsersList ? 'basic' : 'warn'" (click)="showDeletedUsers()">
          <mat-icon fxLayout="row" fxLayoutAlign="center center">
            <svg *ngIf="deletedUsersList" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check" viewBox="0 0 16 16">
              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
              <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
            </svg>
            <svg *ngIf="!deletedUsersList" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-dash" viewBox="0 0 16 16">
              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1m0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
              <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
            </svg>
          </mat-icon>
          {{deletedUsersList ? 'Listar ativos' : 'Listar inativos'}}
        </button>
        <span style="width: 10px;"></span>
        <button mat-stroked-button color="primary" (click)="goToUserRegisterPage()">
          <mat-icon fxLayout="row" fxLayoutAlign="center center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
          </mat-icon>
          Novo usuário
        </button>
      </div>
    </div>
    <div fxFlex="100" class="scdl-crud-container">
      <table fxFlex="100" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Login </th>
          <td mat-cell *matCellDef="let element"> {{element.login}} </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Perfil </th>
          <td mat-cell *matCellDef="let element"> {{element.role.description}} </td>
        </ng-container>

        <ng-container matColumnDef="blocked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.active ? (element.blocked ? 'Bloqueado' : 'Ativo') : 'Inativo'}} </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef> Ações </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Ações"
            (click)="setSelectedUser(element)">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow class="scdl-crud-no-match">
          <td class="mat-cell" colspan="6">Nenhum registro encontrado</td>
        </tr>
      </table>
    </div>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" class="scdl-crud-filter">
      <div fxFlex=""></div>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Selecione uma página"></mat-paginator>
    </div>
  </div>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="editUser()">Editar
    <mat-icon fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
    </svg></mat-icon>
  </button>
  <button *ngIf="selectedUser.active" mat-menu-item (click)="blockUser()">{{selectedUser.blocked ? 'Ativar' : 'Bloquear'}}
    <mat-icon *ngIf="!selectedUser?.blocked" fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
      <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
    </svg></mat-icon>
    <mat-icon *ngIf="selectedUser?.blocked" fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
      <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
    </svg></mat-icon>
  </button>
  <button *ngIf="selectedUser.active" mat-menu-item (click)="confirmDeleteUser()">Excluir
    <mat-icon fxLayout="row" fxLayoutAlign="center center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
    </svg></mat-icon>
  </button>
</mat-menu>
