import { Injectable } from '@angular/core';
import { HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpService } from '../../../../core/http/http.service';
import { LoaderService } from '../../../../shared/components/loader/loader.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MakeSchedulingService {
  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
  ) {

  }

  public resolve(
    data: any,
  ): Observable<any> {
    //this.loaderService.setChangesLoaderState(true);
    const paramns = {
      url: 'client/register',
      data:data,
    };

    return this.httpService.post(paramns).pipe((response: any) => {
      //this.loaderService.setChangesLoaderState(false);
      return response;
    }, catchError(this.errorHandler));

  }


  errorHandler(httpErrorResponse: HttpErrorResponse) {
    console.log('httpErrorResponse ==>>', httpErrorResponse);
    return throwError(() => httpErrorResponse.error);
  }
}

// import { Injectable } from '@angular/core';
// import { HttpErrorResponse} from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { HttpService } from '../../../../core/http/http.service'
// import { catchError } from 'rxjs/operators';


// @Injectable({
//   providedIn: 'root'
// })
// export class MakeSchedulingService {

//   constructor(
//     private httpService: HttpService,
//   ) {

//   }

//   public resolve(
//     data: any
//   ): Observable<any> {
//     const paramns = {
//       url:'client/register',
//       data:data,
//     };

//     return this.httpService.post(paramns).pipe((response: any) => {
//       return response;
//     }, catchError(this.errorHandler));

//   }


//   errorHandler(httpErrorResponse: HttpErrorResponse) {
//     console.log('httpErrorResponse ==>>', httpErrorResponse);
//     return throwError(() => httpErrorResponse.error);
//   }
// }
