import { Injectable } from '@angular/core';
import { HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AdminHttpService } from '../../../../core/adminHttp/admin-http.service';
import { LoaderService } from '../../../../shared/components/loader/loader.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeleteEventService {
  constructor(
    private adminHttpService: AdminHttpService,
    private loaderService: LoaderService,
  ) {

  }

  public resolve(
    id: string,
  ): Observable<any> {
    // //this.loaderService.setChangesLoaderState(true);
    const paramns = {
      url: `event/${id}`,
    };

    return this.adminHttpService.delete(paramns).pipe((response: any) => {
      // //this.loaderService.setChangesLoaderState(false);
      return response;
    }, catchError(this.errorHandler));

  }


  errorHandler(httpErrorResponse: HttpErrorResponse) {

    return throwError(() => httpErrorResponse.error);
  }
}
