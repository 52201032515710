import { Injectable } from '@angular/core';
import { HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AdminHttpService } from '../../../../core/adminHttp/admin-http.service';
import { LoaderService } from '../../../../shared/components/loader/loader.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditUserService {
  constructor(
    private adminHttpService: AdminHttpService,
    private loaderService: LoaderService,
  ) {

  }

  public resolve(
    data: any,
  ): Observable<any> {
    //this.loaderService.setChangesLoaderState(true);
    const paramns = {
      url: 'user/' + data.id,
      data:data,
    };

    return this.adminHttpService.patch(paramns).pipe((response: any) => {
      //this.loaderService.setChangesLoaderState(false);
      return response;
    }, catchError(this.errorHandler));

  }


  errorHandler(httpErrorResponse: HttpErrorResponse) {
    console.log('httpErrorResponse ==>>', httpErrorResponse);
    return throwError(() => httpErrorResponse.error);
  }
}
