<div class="scdl-admin-layout" fxLayout="row" fxLayoutAlign="center start">
  <div fxFlex.gt-md="65" fxFlex.gt-sm="70" fxFlex.gt-xs="80" fxFlex="100">
    <div class="scdl-admin-box-titles with-back-button" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button color="primary" (click)="goToEventsCrudPage()"
      aria-label="Voltar" title="voltar">
        <mat-icon fxLayout="row" fxLayoutAlign="center center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
        </mat-icon>
      </button>
      <h2 class="scdl-admin-titles scdl-light-gray-color"> {{eventData?.id ? 'Editar evento': 'Cadastrar de evento'}}</h2>
    </div>
    <div class="scdl-admin-box-container scdl-padding-20x20 mat-elevation-z8 scdl-white-box" fxLayout="row wrap">
      <form [formGroup]="eventForm"  (ngSubmit)="saveEventData()"
        fxFlex="100">
        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div class="scdl-form-box" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Nome do evento</mat-label>
              <input matInput required formControlName="name"
                  [(ngModel)]="eventData.name" name="name" type="text">
                  <mat-error>Informe o nome do evento</mat-error>
              <mat-hint>Informe o nome do evento</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Tipo</mat-label>
              <mat-select required formControlName="type"
              [(ngModel)]="eventData.type">
                <mat-option *ngFor="let item of eventTypes" [value]="item.id">
                  {{item.description}}
                </mat-option>
              </mat-select>
              <mat-hint>Tipo do evento</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-xs="" fxFlex.gt-sm="25" fxFlex="">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Capacidade total</mat-label>
              <input matInput required formControlName="fullCapacity"
                  [(ngModel)]="eventData.fullCapacity" name="fullCapacity" type="text">
                  <mat-error>Informe a quantidade</mat-error>
              <mat-hint>Máximo de participantes</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-xs="" fxFlex.gt-sm="25" fxFlex="">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Vagas para abertura</mat-label>
              <input matInput required formControlName="openingCapacity"
                  [(ngModel)]="eventData.openingCapacity" name="openingCapacity" type="text">
                  <mat-error>Informe a quantidade</mat-error>
              <mat-hint>Capacidade para assistir abertura</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Período do evento</mat-label>
              <mat-date-range-input
                [formGroup]="eventForm"
                [rangePicker]="campaignTwoPicker">
                <input matStartDate placeholder="Data do início"
                [(ngModel)]="eventData.startDate"
                formControlName="startDate">
                <input matEndDate placeholder="Data do final"
                [(ngModel)]="eventData.endDate"
                formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="campaignTwoPicker"></mat-datepicker-toggle>
              <mat-hint>Data início – Data final</mat-hint>
              <mat-date-range-picker #campaignTwoPicker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="25" fxFlex="">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Chegada para abertura</mat-label>
              <mat-select required formControlName="hourWatchOpening"
              [(ngModel)]="eventData.hourWatchOpening">
                <mat-option *ngFor="let hour of eventhours" [value]="hour">
                  {{hour}}
                </mat-option>
              </mat-select>
              <mat-hint>Horário da abertura</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="25" fxFlex="">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Chegada pós abertura</mat-label>
              <mat-select required formControlName="hourAfterOpening"
              [(ngModel)]="eventData.hourAfterOpening">
                <mat-option *ngFor="let hour of eventhours" [value]="hour">
                  {{hour}}
                </mat-option>
              </mat-select>
              <mat-hint>Início do evento</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Período de agendamento</mat-label>
              <mat-date-range-input
                [formGroup]="eventForm"
                [rangePicker]="campaignOnePicker">
                <input matStartDate placeholder="Data do início"
                [(ngModel)]="eventData.serviceStartDate"
                formControlName="serviceStartDate">
                <input matEndDate placeholder="Data do final"
                [(ngModel)]="eventData.serviceEndDate"
                formControlName="serviceEndDate">
              </mat-date-range-input>
              <mat-hint>Data início – Data final</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="25" fxFlex="">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Início do agendamento</mat-label>
              <mat-select required formControlName="serviceStartHour"
              [(ngModel)]="eventData.serviceStartHour">
                <mat-option *ngFor="let hour of hours" [value]="hour">
                  {{(hour < 10 ? '0' : '') + hour + ':00' }}
                </mat-option>
              </mat-select>
              <mat-hint>Horário de início</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex.gt-sm="25" fxFlex="">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" class="scdl-datepicker-fake-not-disabled">
              <mat-label>Final do agendamento</mat-label>
              <mat-select required formControlName="serviceEndHour"
              [(ngModel)]="eventData.serviceEndHour">
                <mat-option *ngFor="let hour of hours" [value]="hour">
                  {{(hour < 10 ? '0' : '') + hour + ':00' }}
                </mat-option>
              </mat-select>
              <mat-hint>Horário de encerramento</mat-hint>
            </mat-form-field>
          </div>
          <div class="scdl-form-box" fxFlex="100">
            <div fxLayout="column">
              <mat-label>Status</mat-label>
              <mat-radio-group color="primary" formControlName="status" [(ngModel)]="eventData.status" name="status"
              fxLayout="row wrap">
                <mat-radio-button *ngFor="let status of statusTypes"
                style="margin-right: 15px;"
                [value]="status.id">{{status.description}}</mat-radio-button>
                <!-- <span style="width: 15px;"></span>
                <mat-radio-button value="ATIVED">Ativo</mat-radio-button> -->
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="20px">
          <div class="scdl-form-box" fxFlex.gt-sm="" fxFlex="100">
            <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
              <mat-label>Descrição do evento</mat-label>
                  <textarea matInput formControlName="description"
                  [(ngModel)]="eventData.description" name="description">
                  </textarea>
                  <mat-error>Descreva o evento</mat-error>
              <mat-hint>Descreva o evento</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="scdl-container-box" fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button color="primary" [disabled]="eventForm.invalid"
          type="submit">Salvar</button>
        </div>
      </form>
    </div>
  </div>
</div>

