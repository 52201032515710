import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import * as moment from 'moment';
import { SaveEventService } from '../services/save-event.service';
import { EditEventService } from '../services/edit-event.service';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { LoaderService } from '../../../../shared/components/loader/loader.service';
@Component({
  selector: 'app-event-register',
  templateUrl: './event-register.component.html',
  styleUrls: ['./event-register.component.scss']
})
export class EventRegisterComponent implements OnInit{

  public eventForm: FormGroup;
  public eventTypes: any[] = [];
  public statusTypes: any[] = [];
  public eventData: any = {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
    serviceStartDate: '',
    serviceEndDate: '',
    fullCapacity: '',
    openingCapacity: '',
    status: '',
    type: '',
    description: '',
    hourWatchOpening: '',
    hourAfterOpening: '',
    serviceStartHour: '',
    serviceEndHour: '',
  }
  public hours: any[] = [];
  public eventhours: any[] = [];
  public isDisabled: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    private loaderService : LoaderService,
    private saveEventService : SaveEventService ,
    private editEventService : EditEventService ,
    public feedbackMessagesComponent: FeedbackMessagesComponent,
    // private makeSchedulingService : MakeSchedulingService ,
  ) {
    this.buildHourOptions();
    this.buildEventData();
    this.buildHourFormat();
    this.buildForm();
  }

  buildHourOptions() {
    for (let i = 0; i < 24; i++) {
      this.hours.push(i);
      this.eventhours.push( (i < 10 ? '0' : '') + i + ':00' );
      this.eventhours.push( (i < 10 ? '0' : '') + i + ':15' );
      this.eventhours.push( (i < 10 ? '0' : '') + i + ':30' );
      this.eventhours.push( (i < 10 ? '0' : '') + i + ':45' );
    }
  }

  buildHourFormat() {
    this.eventData.serviceStartHour = this.eventData.serviceStartDate ? this.getHour(this.eventData.serviceStartDate) : '';
    this.eventData.serviceEndHour = this.eventData.serviceEndDate ? this.getHour(this.eventData.serviceEndDate) : '';
  }

  getHour(date: Date) {
    return moment(date).get('hour');
  }

  buildEventData() {
    this.eventData = this.activatedRoute.snapshot.data['getEventDataService'] ? this.activatedRoute.snapshot.data['getEventDataService'] : {status: 'SCHEDULED'};
    this.eventTypes = this.activatedRoute.snapshot.data['getEventTypesService'] ? this.activatedRoute.snapshot.data['getEventTypesService'] : new Array();
    this.statusTypes = this.activatedRoute.snapshot.data['getSessionStatusTypesService'] ? this.activatedRoute.snapshot.data['getSessionStatusTypesService'] : new Array();
  }

  buildForm() {
    this.eventForm = this.formBuilder.group({
      name: new FormControl(this.eventData.name, [
        Validators.required,
        Validators.minLength(3)
      ]),
      serviceStartHour: new FormControl(this.eventData.serviceStartHour, [
        Validators.required,
      ]),
      serviceEndHour: new FormControl(this.eventData.serviceEndHour, [
        Validators.required,
      ]),
      hourWatchOpening: new FormControl(this.eventData.hourWatchOpening, [
        Validators.required,
      ]),
      hourAfterOpening: new FormControl(this.eventData.hourAfterOpening, [
        Validators.required,
      ]),
      serviceStartDate: new FormControl(new Date(), [
        Validators.required,
      ]),
      serviceEndDate: new FormControl(new Date(), [
        Validators.required,
      ]),
      startDate: new FormControl(new Date(), [
        Validators.required,
      ]),
      endDate: new FormControl(new Date(), [
        Validators.required,
      ]),
      fullCapacity: new FormControl(this.eventData.fullCapacity, [
        Validators.required,
      ]),
      openingCapacity: new FormControl(this.eventData.openingCapacity, [
        Validators.required,
      ]),
      status: new FormControl(this.eventData.status, [
        Validators.required,
      ]),
      type: new FormControl(this.eventData.type, [
        Validators.required,
      ]),
      description: new FormControl(this.eventData.description, [
      ]),
    });
  }

  ngOnInit() {
    this.formatClientDates();
  }

  formatClientDates() {
    this.eventData.startDate = this.setFormatDateHour(this.eventData.startDate, 3);
    this.eventData.endDate = this.setFormatDateHour(this.eventData.endDate, 3);
  }

  saveEventData() {
    let sendData = JSON.parse(JSON.stringify(this.eventData));
    sendData.serviceStartDate = this.setFormatDateHour(sendData.serviceStartDate, sendData.serviceStartHour);
    sendData.serviceEndDate = this.setFormatDateHour(sendData.serviceEndDate, sendData.serviceEndHour);
    delete sendData.serviceStartHour;
    delete sendData.serviceEndHour;
    this.loaderService.setChangesLoaderState(true);
    if(this.eventData?.id) {
      this.editEventService.resolve(sendData).subscribe({
        next: (response) => {
          this.responseSaveeventData(response);
          this.loaderService.setChangesLoaderState(false);
        },
        error: (error) => {
          this.feedbackMessagesComponent.showMessageError(error.description);
          this.loaderService.setChangesLoaderState(false);
        },
      })
    } else {
      this.saveEventService.resolve(sendData).subscribe({
        next: (response) => {
          this.responseSaveeventData(response);
          this.loaderService.setChangesLoaderState(false);
        },
        error: (error) => {
          this.feedbackMessagesComponent.showMessageError(error.description);
          this.loaderService.setChangesLoaderState(false);
        },
      })
    }
  }

  setFormatDateHour(date: string, hour: number) {
    return moment(moment(moment(date).set('hour', hour)).locale('GMT-0300')).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  responseSaveeventData(response: any) {
    if(response && response.id) {
      this.eventData.id = response.id;
      this.feedbackMessagesComponent.showMessageSuccess("Evento salvo com sucesso");
    }
  }

  goToEventsCrudPage(){
    this.router.navigate(['admin/eventos']);
  }
}
