<mat-toolbar class="scdl-main-bar">
  <mat-toolbar-row class="mat-elevation-z4">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="scdl-navbar-container-logo"  fxLayout="row" fxLayoutAlign="center center"
      class="scdl-navbar-logo-box">
        <img src="/assets/img/logo.png" alt="logo">
      </div>
      <div fxHide.xs class="scdl-navbar-logo-text" fxLayoutAlign="center start">
        <h2>Guardiões da Luz Divina</h2>
      </div>
    </div>
    <div fxHide.xs fxHide.sm fxFlex fxLayout="row" fxLayoutAlign="end center">
      <button mat-button class="toolbar-btn" routerLinkActive="selectedButton"
      routerLink="{{option.link}}" *ngFor="let option of menuOptions"
      (click)="btnAction(option.logoutButton)"
      title="{{option.title}}">
        {{option.title}}
      </button>
    </div>
    <div fxHide.gt-sm  fxFlex fxLayout="row" fxLayoutAlign="end center">
      <button mat-button [matMenuTriggerFor]="menu">Menu</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item  class="responsive-toolbar-btn" routerLinkActive="selectedButton"
        routerLink="{{option.link}}" *ngFor="let option of menuOptions"
        (click)="btnAction(option.logoutButton)"
        title="{{option.title}}">
          {{option.title}}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
