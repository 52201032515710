import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute} from '@angular/router';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { SchedulingRegisterComponent } from '../../events/scheduling-register/scheduling-register.component';
import { GetEventClientsService } from '../services/get-event-clients.service';
import { StartScheduleQueueComponent } from '../start-schedule-queue/start-schedule-queue.component';
import { UpdateScheduleStatusComponent } from '../update-schedule-status/update-schedule-status.component';

export interface CustomerInterface {
  id: string,
  fullName: string,
  cellPhone: string,
  email: string,
  observation: string,
  position: number,
  securityCode: number,
}

@Component({
  selector: 'app-start-events',
  templateUrl: './start-events.component.html',
  styleUrls: ['./start-events.component.scss']
})
export class StartEventsComponent implements AfterViewInit {

  @ViewChild('waitingPaginator') waitingPaginator: MatPaginator;
  @ViewChild('servedPaginator') servedPaginator: MatPaginator;
  @ViewChild('notServedPaginator') notServedPaginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public eventData: any = {};
  public selectedPerson: any = {};

  public tableId: number = 0;
  public totalSchedules: number = 0;

  displayedColumns: string[] = ['position', 'fullName', 'securityCode', 'cellPhone', 'observation', 'actions'];
  customersWaitingDataSource: MatTableDataSource<CustomerInterface>;
  customersServedDataSource: MatTableDataSource<CustomerInterface>;
  customersNotServedDataSource: MatTableDataSource<CustomerInterface>;


  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    public feedbackMessagesComponent: FeedbackMessagesComponent,
    public getEventClientsService: GetEventClientsService,
    private confirmActionDialog : MatDialog ,
  ) {
    this.eventData = this.activatedRoute.snapshot.data['getEventClientsService'] ? this.activatedRoute.snapshot.data['getEventClientsService'] : {};
    this.updateEventData();
  }

  updateEventData() {
    this.customersWaitingDataSource = new MatTableDataSource(this.eventData.customersWaiting);
    this.customersServedDataSource = new MatTableDataSource(this.eventData.customersServed);
    this.customersNotServedDataSource = new MatTableDataSource(this.eventData.customersNotServed);

    this.totalSchedules = parseInt(this.eventData?.customersWaiting?.length) + parseInt(this.eventData?.customersServed?.length) + parseInt(this.eventData?.customersNotServed?.length);
  }


  ngAfterViewInit() {
    this.customersWaitingDataSource.paginator = this.waitingPaginator;
    this.customersServedDataSource.paginator = this.servedPaginator;
    this.customersNotServedDataSource.paginator = this.notServedPaginator;
  }

  applyFilter(event: Event) {
    this.toLists(event, this.customersWaitingDataSource);
    this.toLists(event, this.customersServedDataSource);
    this.toLists(event, this.customersNotServedDataSource);
  }

  toLists(event: Event, list: any) {
    const filterValue = (event.target as HTMLInputElement).value.normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();
    list.filter = filterValue.trim().normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();

    if (list.paginator) {
      list.paginator.firstPage();
    }
  }

  openSchedulingRegisterDialog(schedulingData: any = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = true;
    dialogConfig.maxWidth = '680px';
    dialogConfig.width = '95vw';
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.data = {
      schedulingData: schedulingData,
      eventData: this.eventData,
      eventIsStarted: true,
    };
    const schedulingRegisterComponentRef = this.confirmActionDialog.open(SchedulingRegisterComponent, dialogConfig);
    schedulingRegisterComponentRef.beforeClosed().subscribe(
      response => {
        if(response && response.id) {
          this.getScheduledClients();
        }
      }
    );
  }

  getScheduledClients(){
    const data = {
      id: this.eventData.id,
    }
    this.getEventClientsService.resolve2(data).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.updateListData(response);
        }
      },
      error: (error) => {
      },
    })
  }

  updateListData(response: any) {
    this.eventData = response;
    this.updateEventData();
  }

  startNextCall(personId: any = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = true;
    dialogConfig.maxWidth = '500px';
    dialogConfig.width = '95vw';
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      customersWaiting: this.eventData.customersWaiting,
      id: this.eventData.id,
      selectedPerson: personId ? this.selectedPerson : null
    };
    const startScheduleQueueComponentRef = this.confirmActionDialog.open(StartScheduleQueueComponent, dialogConfig);
    startScheduleQueueComponentRef.beforeClosed().subscribe(
      response => {
        this.selectedPerson = {};
        this.getScheduledClients();
      }
    );
  }

  goToCurrentEventsPage(){
    this.router.navigate(['admin/atendimento']);
  }

  setselectedPerson(obj: any, tableId: number) {
    this.tableId = tableId;
    this.selectedPerson = JSON.parse(JSON.stringify(obj));
  }

  updatePersonStatus(personId: any = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = true;
    dialogConfig.maxWidth = '500px';
    dialogConfig.width = '95vw';
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      customersWaiting: this.eventData.customersWaiting,
      id: this.eventData.id,
      selectedPerson: personId ? this.selectedPerson : null
    };
    const updateScheduleStatusComponentRef = this.confirmActionDialog.open(UpdateScheduleStatusComponent, dialogConfig);
    updateScheduleStatusComponentRef.beforeClosed().subscribe(
      response => {
        this.selectedPerson = {};
        this.getScheduledClients();
      }
    );
  }

}
