import { Component, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { DeleteUserService } from '../services/delete-user.service';
import { GetDeletedUsersService } from '../services/get-deleted-users.service';
import { SetUserStatusService } from '../services/set-user-status.service';
import { LoaderService } from '../../../../shared/components/loader/loader.service';
import { ConfirmDeleteRegisterDialogComponent } from '../../../../shared/components/confirm-delete-register-dialog/confirm-delete-register-dialog.component';
import { User } from "../models/user";

@Component({
  selector: 'app-users-crud',
  templateUrl: './users-crud.component.html',
  styleUrls: ['./users-crud.component.scss']
})
export class UsersCrudComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['username', 'email', 'login', 'role', 'blocked', 'actions'];
  users: User[] = [];
  deletedUsers: User[] = [];
  dataSource: MatTableDataSource<User>;
  selectedUser: any = {};
  deletedUsersList: boolean = false;
  searchBar: string = '';

  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    private loaderService : LoaderService,
    private changeDetectorRef : ChangeDetectorRef ,
    private feedbackMessagesComponent : FeedbackMessagesComponent ,
    private deleteUserService : DeleteUserService ,
    private getDeletedUsersService : GetDeletedUsersService ,
    private setUserStatusService : SetUserStatusService ,
    private confirmActionDialog : MatDialog ,
  ) {
    this.users = this.activatedRoute.snapshot.data['getUsersService'];
    this.deletedUsers = this.activatedRoute.snapshot.data['getDeletedUsersService'];
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter() {
    // const filterValue = (event.target as HTMLInputElement).value.normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();
    const filterValue = this.searchBar.normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();
    this.dataSource.filter = filterValue.trim().normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setSelectedUser(obj: any){
    this.selectedUser = JSON.parse(JSON.stringify(obj));
  }


  editUser() {
    this.goToUserRegisterPage(this.selectedUser.id);
  }

  goToUserRegisterPage(id = null){
    if(id) {
      this.router.navigate(['admin/cadastro-usuario/'+id]);
    } else {
      this.router.navigate(['admin/cadastro-usuario']);
    }
  }


  confirmDeleteUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.data = {
      mainTitleDialog: 'Excluir usuário',
      messageDialog: 'Deseja realmente excluir este registro?',
    };
    const confirmActionDialogComponentRef = this.confirmActionDialog.open(ConfirmDeleteRegisterDialogComponent, dialogConfig);
    confirmActionDialogComponentRef.beforeClosed().subscribe(
      response => {
        if (response) {
          this.deleteUser();
        }
      }
    );
  }

  deleteUser() {
    this.loaderService.setChangesLoaderState(true);
    this.deleteUserService.resolve(this.selectedUser.id).subscribe({
      next: (response) => {
        this.removeDataDeletedFromTable(response);
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }

  removeDataDeletedFromTable(response: any) {
    if(response && response.id) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === response.id) {
          this.users.splice(i, 1);
        }
      }
      this.dataSource.data = this.users;
      this.feedbackMessagesComponent.showMessageSuccess("Usuário removido com sucesso");
    }
  }

  blockUser() {
    const data = {
      id: this.selectedUser.id,
      status: this.selectedUser.blocked ? 'UNLOCK' : 'BLOCK',
    }
    this.loaderService.setChangesLoaderState(true);
    this.setUserStatusService.resolve(data).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.feedbackMessagesComponent.showMessageSuccess("Status alterado com sucesso");
          this.updateUserStatus(response);
        } else {
          this.feedbackMessagesComponent.showMessageError("O status não pôde ser alterado");
        }
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }

  updateUserStatus(response: any) {
    for (const user of this.users) {
      if(user.id === response.id) {
        user.blocked = !user.blocked;
      }
    }
  }

  showDeletedUsers() {
    this.deletedUsersList = !this.deletedUsersList;
    if(this.deletedUsersList) {
      this.getDeletedUsers();
    } else {
      this.dataSource = new MatTableDataSource(this.users);
      this.applyFilter();
    }
  }


  getDeletedUsers() {
    this.loaderService.setChangesLoaderState(true);
    this.getDeletedUsersService.resolve(this.selectedUser.id).subscribe({
      next: (response) => {
        this.deletedUsers = response;
        this.dataSource = new MatTableDataSource(this.deletedUsers);
        this.applyFilter();
        this.loaderService.setChangesLoaderState(false);
      }
    })
  }

}
