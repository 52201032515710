import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PlatformLocation } from '@angular/common';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface HttpResponse {
  status: string;
  data: any;
  success: string;
  message: string;
}


@Injectable({
  providedIn: 'root'
})
export class AdminHttpService {

  private readonly API = `${environment.API}`;
  private readonly authorization = localStorage.getItem('$scdlAdmToken');

  private readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.authorization ? 'Bearer ' + this.authorization : '',
    })
  };

  constructor(
    private httpClient: HttpClient,
    private platformLocation: PlatformLocation,
  ) { }

  get(paramns: any): Observable<HttpResponse> {
    let urlParamns = '';
    if(paramns.data) {
      let first = true;
      for (const key in paramns.data) {
          if(first) {
            urlParamns+= '?' + key + '=' + paramns.data[key];
            first = false;
          } else {
            urlParamns+= '&' + key + '=' + paramns.data[key];
          }
      }
    }
    return this.httpClient.get(this.API + paramns.url + urlParamns, this.options).pipe(tap((response: any) => response));
  }

  post(paramns: any): Observable<HttpResponse>  {
    return this.httpClient.post<any>(this.API + paramns.url, paramns.data, this.options);
  }

  patch(paramns: any): Observable<HttpResponse>  {
    return this.httpClient.patch<any>(this.API + paramns.url, paramns.data, this.options);
  }

  put(paramns: any): Observable<HttpResponse> {
    return this.httpClient.put(this.API + paramns.url, paramns.data, this.options).pipe(tap((response: any) => response));
  }

  delete(paramns: any): Observable<HttpResponse>  {
    return this.httpClient.delete<any>(this.API + paramns.url, this.options);
  }

  errorHandler(httpErrorResponse: HttpErrorResponse) {
    return httpErrorResponse.error;
  }


}
