import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateClientStatusService } from '../services/update-client-status.service';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { LoaderService } from '../../../../shared/components/loader/loader.service';

@Component({
  selector: 'app-update-schedule-status',
  templateUrl: './update-schedule-status.component.html',
  styleUrls: ['./update-schedule-status.component.scss']
})

export class UpdateScheduleStatusComponent implements OnInit {

  public selectedPerson: any =  {};
  public eventData: any =  {};

  constructor(
    private dialogRef: MatDialogRef<UpdateScheduleStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private updateClientStatusService : UpdateClientStatusService,
    private feedbackMessagesComponent : FeedbackMessagesComponent,
    private loaderService : LoaderService,
  ) {
  }

  ngOnInit() {
    this.eventData = this.dataDialog;
    this.selectedPerson = this.eventData ? this.eventData.selectedPerson : {};
  }

  updateStatus(status: string) {
    const data = {
      id: this.selectedPerson.id,
      status: status,
      eventId: this.eventData.id,
    }
    this.loaderService.setChangesLoaderState(true);
    this.updateClientStatusService.resolve(data).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.feedbackMessagesComponent.showMessageSuccess("Status alterado com sucesso");
        } else {
          this.feedbackMessagesComponent.showMessageError("O status não pôde ser alterado");
        }
        this.closePopup();
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }

  closePopup() {
    this.dialogRef.close(true);
  }

}
