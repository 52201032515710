import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateClientStatusService } from '../services/update-client-status.service';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { LoaderService } from '../../../../shared/components/loader/loader.service';

@Component({
  selector: 'app-start-schedule-queue',
  templateUrl: './start-schedule-queue.component.html',
  styleUrls: ['./start-schedule-queue.component.scss']
})
export class StartScheduleQueueComponent  implements OnInit {

  public singlePerson: boolean =  false;
  public showQueue: boolean =  false;
  public queueSize: number =  10;
  public countLoop: number =  0;
  public currentPerson: any =  {};

  public eventData: any = {
  };

  constructor(
    private dialogRef: MatDialogRef<StartScheduleQueueComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    private updateClientStatusService : UpdateClientStatusService,
    private feedbackMessagesComponent : FeedbackMessagesComponent,
    private loaderService : LoaderService,
  ) {
  }

  ngOnInit() {
    this.eventData = this.dataDialog;

    if(this.dataDialog?.selectedPerson) {
      this.buildSinglePerson();
    } else {
      this.buildQueueSize();
    }
  }

  buildQueueSize() {
    if(this.dataDialog?.customersWaiting && this.dataDialog?.customersWaiting.length) {
      this.queueSize = this.dataDialog?.customersWaiting.length < this.queueSize ? this.dataDialog?.customersWaiting.length : this.queueSize;
    }
  }

  buildSinglePerson() {
    this.currentPerson = JSON.parse(JSON.stringify(this.dataDialog?.selectedPerson));
    this.queueSize = 1;
    this.showQueue = true;
    this.singlePerson = true;
  }

  showNextPerson() {
    if(this.countLoop < this.queueSize && this.countLoop < this.dataDialog?.customersWaiting.length) {
      this.showQueue = true;
      this.currentPerson = JSON.parse(JSON.stringify(this.dataDialog?.customersWaiting[this.countLoop]));
    } else {
      this.closePopup();
    }
  }

  closePopup() {
    this.dialogRef.close(true);
  }

  passPerson() {
    this.dataDialog?.customersWaiting.splice(this.countLoop, 1);
    this.showNextPerson();
  }


  updatePersonStatus(served:boolean) {
    const data = {
      id: this.currentPerson.id,
      status: served ? 'SERVICED' : 'NOT_SERVICED',
      eventId: this.eventData.id,
    }
    this.loaderService.setChangesLoaderState(true);
    this.updateClientStatusService.resolve(data).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.feedbackMessagesComponent.showMessageSuccess("Status alterado com sucesso");
          this.manageUpdateActions(served)
        } else {
          this.feedbackMessagesComponent.showMessageError("O status não pôde ser alterado");
        }
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }


  manageUpdateActions(served:boolean) {
    if(this.singlePerson) {
      this.closePopup();
    } else if(served) {
      this.countLoop++;
      this.showNextPerson()
    } else {
      this.passPerson();
    }
  }

}
