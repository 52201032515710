import { Component } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {

  public dataEvents: any;

  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute ,
  ) {
    this.buildEvents(this.activatedRoute.snapshot.data['listEventsService']);
  }

  public goToEventDatails(eventId: number) {
    this.router.navigate(['detalhes-evento', eventId]);
  }

  public buildEvents(events: any) {
    this.dataEvents = events;
  }

}
