<div class="scdl-about-section" style="padding-top: 20px; padding-bottom: 20px;">
  <div class="decoration-border-before"></div>
  <div class="scdl-about-container" fxLayout="row" fxLayoutAlign="center center" >
    <div fxFlex="100" class="scdl-content-width" fxLayout="row wrap" fxLayoutAlign="center start">
      <div class="scdl-about-columns-container" fxFlex="50" fxFlex.xs="100">
        <h3 class="scdl-default-titles"> Nossa <span class="scdl-primary-color">Missão</span></h3>
        <h2 class="scdl-default-titles">Missão do Guardiões da Luz Divina</h2>
        <p>No Guardiões da Luz Divina, nossa missão é simples e direta. Queremos orientar quem busca entender melhor sua jornada espiritual.</p>
        <p>Em nosso espaço acolhedor, focamos em conectar pessoas, dividir  a vida e seus momentos, e oferecer apoio quando necessário. </p>
        <p>Buscamos, acima de tudo, incentivar o crescimento pessoal de cada um, sempre com fundamentos espirituais e compreensão. Nosso desejo é ser um ponto de luz e amizade para todos que nos procuram fazer parte de nossa comunidade.</p>
      </div>
      <div class="scdl-about-columns-container" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center start">
        <div class="scdl-about-box-img">
          <img src="/assets/img/mission.jpg" alt="Quem somos">
        </div>
      </div>
    </div>
  </div>
  <div class="decoration-border-after"></div>
</div>
