<div mat-dialog-title class="scdl-dialog-title" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="" class="scdl-dialog-titleContainer"
  style="padding-right: 12px;">
    <h2 fxFlex="" *ngIf="!showQueue">Chamar próximos agendamentos:</h2>
    <h2 fxFlex="" *ngIf="showQueue">Chamada {{countLoop + 1}} de {{queueSize}} </h2>
    <button *ngIf="showQueue" mat-icon-button aria-label="Interromper fila" [mat-dialog-close]="false"
      matTooltip="Interromper fila">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<!-- Escolher quantidade agendamentos -->
<mat-dialog-content class="mat-typography" *ngIf="!showQueue">
  <p>Escolha a quantidade de pessoas para a próxima chamada:</p>
  <mat-form-field appearance="fill" [floatLabel]="'always'" >
    <mat-label>Quantidade de pessoas</mat-label>
    <input matInput required type="number"
    min="1"
    max="{{dataDialog?.customersWaiting?.length}}"
    [(ngModel)]="queueSize" >
        <mat-error *ngIf="queueSize<1">Informe uma quantidade válida</mat-error>
        <mat-error *ngIf="queueSize>dataDialog?.customersWaiting?.length">Quantidade indisponível. O limite é {{dataDialog?.customersWaiting?.length}} pessoas</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!showQueue">
  <button mat-stroked-button
  color="primary"
  [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>
  <button mat-raised-button
  [disabled]="queueSize<1 || queueSize>dataDialog?.customersWaiting?.length"
  color="primary"
  (click)="showNextPerson()">Iniciar fila</button>
</mat-dialog-actions>

<!-- Pessoa chamada -->
<mat-dialog-content class="mat-typography" *ngIf="showQueue">
  <div class="details-person-scheduled-queue">
    <mat-label>Nome: </mat-label> <span>{{currentPerson.fullName}}</span>
  </div>
  <div class="details-person-scheduled-queue">
    <mat-label>Posição na fila: </mat-label> <span>{{currentPerson.position}}</span>
  </div>
  <div class="details-person-scheduled-queue" *ngIf="currentPerson.priority">
    <mat-label>Atendimento prioritário: </mat-label> <span>{{currentPerson.priority ? 'Sim' : 'Não'}}</span>
  </div>
  <!-- <div class="details-person-scheduled-queue">
    <mat-label>Código: </mat-label> <span>{{currentPerson.securityCode}}</span>
  </div> -->
  <div class="details-person-scheduled-queue">
    <mat-label>Telefone: </mat-label> <span>{{currentPerson.cellPhone}}</span>
  </div>
  <div *ngIf="currentPerson.observation" class="details-person-scheduled-queue">
    <mat-label>Observação: </mat-label> <span>{{currentPerson.observation}}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="showQueue"
class="person-scheduled-queue-actions">
  <!-- <button mat-stroked-button
  color="accent">Interromper fila</button> -->
  <button mat-stroked-button
  matTooltip="Alterar status da pessoa para ausente ou cancelado"
  color="warn" fxFlex.xs="100"
  (click)="updatePersonStatus(false)">Pessoa ausente</button>
  <button mat-stroked-button
  *ngIf="!singlePerson"
  matTooltip="Manter a pessoa na fila aguardando"
  color="primary" fxFlex.xs="100"
  (click)="passPerson()">Pular pessoa</button>
  <button mat-raised-button
  matTooltip="Alterar status da pessoa para atendido"
  color="primary" fxFlex.xs="100"
  (click)="updatePersonStatus(true)">Atender pessoa</button>
</mat-dialog-actions>
