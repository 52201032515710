<div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 40px; padding-bottom: 20px;">
  <div fxFlex="100" class="scdl-content-width" fxLayout="column" fxLayoutAlign="center center"
  *ngIf="!eventData || eventData.status?.id!=='AVAILABLE_SCHEDULING'">
    <h6 class="scdl-default-titles scdl-titles scdl-light-gray-color">Este evento está esgotado ou indisponível</h6>
    <button mat-raised-button color="primary" (click)="goToHome()">Voltar para a página principal</button>
  </div>
  <div fxFlex="100" class="scdl-content-width" *ngIf="eventData.status?.id==='AVAILABLE_SCHEDULING'">
    <div class="scdl-title-box" *ngIf="!agendamentoRealizado" fxLayout="column" fxLayoutAlign="center center">
      <h2 class="scdl-default-titles scdl-titles scdl-gray-color" style="text-align: center;" >{{eventData.name}}</h2>
      <p *ngIf="eventData?.description" class="scdl-default-titles scdl-titles scdl-primary-color">{{showSeeMore ? eventData.littleDescription : eventData.description}}
        <span *ngIf="eventData?.littleDescription?.length" class="show-see-more" (click)="showSeeMore = !showSeeMore">&nbsp;&nbsp;&nbsp;<b>{{showSeeMore ? 'Leia mais >>' : '<< Leia menos' }}</b></span>
      </p>
      <div class="event-datails-box-container">
        <p><label class="scdl-label-titles">Data: </label> {{eventData.startDate | date : 'dd/MM/yyyy'}}</p>
        <p><label class="scdl-label-titles">Total de vagas: </label> {{eventData.fullCapacity}}</p>
      </div>
    </div>
    <div class="scdl-container-box" *ngIf="!agendamentoRealizado" fxLayout="row" fxLayoutAlign="center center">
      <form [formGroup]="registerForm"  (ngSubmit)="saveScheduling()"
      fxFlex.gt-md="40" fxFlex.gt-sm="60" fxFlex.gt-xs="80" fxFlex="100" fxLayout="row wrap"
      fxLayoutAlign="space-between center">
        <div class="scdl-form-box" fxFlex="100">
          <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
            <mat-label>Nome completo</mat-label>
            <input matInput required [pattern]="namePattern" formControlName="fullName"
                [(ngModel)]="registerData.fullName" name="fullName" type="text">
                <mat-error>Informe o nome e sobrenome</mat-error>
            <mat-hint>Informe o nome e sobrenome</mat-hint>
          </mat-form-field>
        </div>
        <div class="scdl-form-box" fxFlex="35" fxFlex.xs="100">
          <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
            <mat-label>Telefone</mat-label>
            <input matInput required [formControlName]="'cellPhone'"
            mask="{{registerData.cellPhone && this.registerData.cellPhone.length > 10 ? '(00) 00000-0009' : '(00) 0000-00009' }}"
            [(ngModel)]="registerData.cellPhone" name="cellPhone" type="text"
            [showMaskTyped]="false" [dropSpecialCharacters]="true">
            <mat-error>Telefone inválido</mat-error>
          </mat-form-field>
        </div>
        <div class="scdl-form-box" fxFlex="60" fxFlex.xs="100" *ngIf="eventData?.type?.id === 'CACAO_CEREMONY'">
          <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
            <mat-label>E-mail</mat-label>
            <input matInput [pattern]="emailPattern" formControlName="email"
              [(ngModel)]="registerData.email" name="email" type="email">
            <mat-error>E-mail inválido</mat-error>
          </mat-form-field>
        </div>
        <!-- <div class="scdl-form-box" fxFlex="100">
          <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
            <mat-label>Observação</mat-label>
            <textarea matInput formControlName="observation"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
            maxlength="255"
              [(ngModel)]="registerData.observation" name="observation" type="observation">
            </textarea>
            <mat-hint align="end">
              {{registerData.observation && registerData.observation.length ? registerData.observation.length : 0}} / 255
            </mat-hint>
          </mat-form-field>
        </div> -->
        <div class="scdl-form-box" fxFlex="100" fxLayoutAlign="end center">
            <button mat-stroked-button type="reset"
            [disabled]="registerForm.untouched">Cancelar</button>
            <span style="width: 10px;"></span>
            <button mat-raised-button color="primary" type="submit"
            [disabled]="registerForm.invalid">Agendar</button>
        </div>
      </form>
    </div>
    <div class="scdl-container-box" *ngIf="agendamentoRealizado" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex.gt-md="40" fxFlex.gt-sm="60" fxFlex.gt-xs="80" fxFlex="100">
        <div fxLayoutAlign="center center" class="scdl-titles scdl-third-color scdl-padding-20x20" style="color: black;">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-calendar2-check" viewBox="0 0 16 16">
            <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
          </svg>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h2 class="scdl-default-titles scdl-titles scdl-light-green-color" style="color: black;">
            {{scheluledData.name}}, agendamento realizado com sucesso!
          </h2>
        </div>
        <div [innerHTML]="scheluledData.message"></div>
        <div class="scdl-container-box" fxLayout="column" fxLayoutAlign="center center">
          <button mat-stroked-button (click)="newScheduling()">Realizar um novo agendamento</button>
          <span style="height: 10px;"></span>
          <button mat-raised-button color="primary" (click)="goToHome()">Ir para a página principal</button>
        </div>
      </div>
    </div>
  </div>
</div>
