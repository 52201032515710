import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, NavigationError} from '@angular/router';
import { LoaderService } from '../../../shared/components/loader/loader.service';

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.scss']
})
export class AdminRootComponent implements OnInit, AfterViewInit {

  private jwtDecoded: any;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.router.events
    .subscribe((event) => {
          // this.verifyUser();
          if (event instanceof NavigationStart) {
            this.loaderService.setChangesLoaderState(true);
          } else if (
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel
            ) {
              this.loaderService.setChangesLoaderState(false);
          } else if(event instanceof NavigationError) {
            this.loaderService.setChangesLoaderState(false);
          }
      });
  }

}
