import { Component, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackMessagesComponent } from '../../../../shared/components/feedback-messages/feedback-messages.component';
import { UpdateEventStatusService } from '../services/update-event-status.service';
import { GetScheduledClientsService } from '../services/get-scheduled-clients.service';
import { DeleteEventService } from '../services/delete-event.service';
import { ConfirmDeleteRegisterDialogComponent } from '../../../../shared/components/confirm-delete-register-dialog/confirm-delete-register-dialog.component';
import { SchedulingsListByEventComponent } from '../schedulings-list-by-event/schedulings-list-by-event.component';
import { EventInterface } from "../models/eventInterface";
import { LoaderService } from '../../../../shared/components/loader/loader.service';

@Component({
  selector: 'app-events-crud',
  templateUrl: './events-crud.component.html',
  styleUrls: ['./events-crud.component.scss']
})
export class EventsCrudComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'startDate', 'fullCapacity', 'openingCapacity', 'status', 'actions'];
  events: EventInterface[] = [];
  dataSource: MatTableDataSource<EventInterface>;
  selectedEvent: any = {};

  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    private loaderService : LoaderService,
    private changeDetectorRef : ChangeDetectorRef ,
    private feedbackMessagesComponent : FeedbackMessagesComponent,
    private updateEventStatusService : UpdateEventStatusService,
    private getScheduledClientsService : GetScheduledClientsService,
    private deleteEventService : DeleteEventService,
    private confirmActionDialog : MatDialog,
  ) {
    this.events = this.activatedRoute.snapshot.data['getEventsService'];
    this.dataSource = new MatTableDataSource(this.events);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();
    this.dataSource.filter = filterValue.trim().normalize('NFD').replace(/[\\\[\]\?\+\*\(\)\u0300-\u036f]/g, '').toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setselectedEvent(obj: any){
    this.selectedEvent = JSON.parse(JSON.stringify(obj));
  }


  editEvent() {
    this.goToEventRegisterPage(this.selectedEvent.id);
  }

  goToEventRegisterPage(id = null){
    if(id) {
      this.router.navigate(['admin/cadastro-evento/'+id]);
    } else {
      this.router.navigate(['admin/cadastro-evento']);
    }
  }


  confirmDeleteEvent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.restoreFocus = false;
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.data = {
      mainTitleDialog: 'Excluir evento',
      messageDialog: `Deseja realmente excluir o evento "${this.selectedEvent.name}"?`,
    };
    const confirmActionDialogComponentRef = this.confirmActionDialog.open(ConfirmDeleteRegisterDialogComponent, dialogConfig);
    confirmActionDialogComponentRef.beforeClosed().subscribe(
      response => {
        if (response) {
          this.deleteEvent();
        }
      }
    );
  }

  deleteEvent() {
    this.loaderService.setChangesLoaderState(true);
    this.deleteEventService.resolve(this.selectedEvent.id).subscribe({
      next: (response) => {
        this.removeDataDeletedFromTable(response);
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }

  removeDataDeletedFromTable(response: any) {
    if(response && response.id) {
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i].id === response.id) {
          this.events.splice(i, 1);
        }
      }
      this.dataSource.data = this.events;
      this.feedbackMessagesComponent.showMessageSuccess("Evento removido com sucesso");
    }
  }

  updateEventStatus() {
    const data = {
      id: this.selectedEvent.id,
      status: this.selectedEvent?.status?.id === 'SCHEDULED' ? 'AVAILABLE_SCHEDULING' : 'SCHEDULED',
    }
    this.updateEventStatusService.resolve(data).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.feedbackMessagesComponent.showMessageSuccess("Status alterado com sucesso");
          this.updateTableRegister(response);
        } else {
          this.feedbackMessagesComponent.showMessageError("O status não pôde ser alterado");
        }
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description)
      },
    })
  }

  updateTableRegister(response: any) {
    for (const event of this.events) {
      if(event.id === response.id) {
        event.status = response.status;
      }
    }
  }

  getScheduledClients() {
    const data = {
      id: this.selectedEvent.id,
    }
    this.getScheduledClientsService.resolve2(data).subscribe({
      next: (response) => {
        this.openPopupSchedulings(response);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description)
      },
    })
  }

  openPopupSchedulings(schedulings: any[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = true;
    dialogConfig.maxHeight = '100vh';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.height = '95vh';
    dialogConfig.width = '95vw';
    dialogConfig.panelClass = 'scdl-dialog-container';
    dialogConfig.data = {
      schedulings: schedulings,
      eventData: this.selectedEvent,
    };
    const schedulingsListByEventComponentRef = this.confirmActionDialog.open(SchedulingsListByEventComponent, dialogConfig);
    schedulingsListByEventComponentRef.beforeClosed().subscribe(
      response => {
      }
    );
  }

}
