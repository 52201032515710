import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { MakeSchedulingService } from '../../services/make-scheduling.service';
import { FeedbackMessagesComponent } from '../../../../../shared/components/feedback-messages/feedback-messages.component';
import { LoaderService } from '../../../../../shared/components/loader/loader.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit {

  private eventId: any = '';
  public scheluledData: any = {};
  public eventData: any = {};
  public eventList: any[] = [];
  public agendamentoRealizado: boolean = false;
  public showSeeMore: boolean = false;
  public registerForm: FormGroup;
  public registerData: any = {
    fullName: '',
    email: '',
    cellPhone: '',
    // observation: '',
  }

  public namePattern = '^[A-Za-z]*.* [a-zA-ZÀ-ú]{2,50}[ ]*$';
  public emailPattern = '^(([0-9a-zA-Z]+[-._+&])*[-.+&0-9a-zA-Z_]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}){0,1}$';
  public phonePattern = '[\(]?[0-9]{2}[\)]?\ \?[0-9]{8,9}';

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute : ActivatedRoute ,
    private makeSchedulingService : MakeSchedulingService ,
    public feedbackMessagesComponent: FeedbackMessagesComponent,
    private loaderService : LoaderService,
  ) {

    this.registerForm = this.formBuilder.group({
      fullName: new FormControl(this.registerData.fullName, [
        Validators.required,
        Validators.minLength(3)
      ]),
      email: new FormControl(this.registerData.email, [
        Validators.email,
        // Validators.required,
        Validators.minLength(5)
      ]),
      cellPhone: new FormControl(this.registerData.cellPhone, [
        Validators.required,
        Validators.pattern(/^[(]?[0-9]{2}[\)]?\s?\d{4,5}-?\d{4}$/)
      ]),
      // observation: new FormControl(this.registerData.observation, [
      // ]),
    });
  }

  ngOnInit() {
    this.getEventData();
  }

  getEventData() {
    this.eventList = this.activatedRoute.snapshot.data['listEventsService']['activedEvents'];
    this.eventId = this.activatedRoute.snapshot.params['id'];
    for (const item of this.eventList) {
      if(item.id === this.eventId) {
        this.eventData = item;
        this.buildDescription();
      }
    }
  }

  buildDescription() {
    if(this.eventData.description && this.eventData.description.length > 320) {
      this.eventData.littleDescription = this.eventData.description.slice(0, 320) + '...';
      this.showSeeMore = true;
    }
  }

  ngAfterViewInit() {
    this.scrollToEventDetails();
  }

  scrollToEventDetails() {
    setTimeout(() => {
      const elm =  document.querySelector('#eventDetails') as HTMLElement;
      if(!elm) return ;
      this.scrollTo(elm.offsetTop - 100);
    }, 500);
  }

  scrollTo(top: number) {
    scroll({
      top: top,
      behavior: "smooth"
    })
  }

  saveScheduling() {
    let sendData = JSON.parse(JSON.stringify(this.registerData));
    sendData.idEvent = this.eventId;
    this.loaderService.setChangesLoaderState(true);
    this.makeSchedulingService.resolve(sendData).subscribe({
      next: (response) => {
        if(response && response.id) {
          this.scheluledData = response;
          this.scheluledData.message = this.sanitizer.bypassSecurityTrustHtml(this.scheluledData.message);
          this.scheluledData.name = sendData.fullName?.split(' ')[0] ? sendData.fullName?.split(' ')[0] : sendData.fullName;
          this.agendamentoRealizado = true;
          this.scrollToEventDetails();
        }
        this.loaderService.setChangesLoaderState(false);
      },
      error: (error) => {
        this.feedbackMessagesComponent.showMessageError(error.description);
        this.loaderService.setChangesLoaderState(false);
      },
    })
  }

  newScheduling() {
    this.registerData = {
      fullName: '',
      email: '',
      cellPhone: '',
      // observation: '',
    };
    this.registerForm.reset();
    this.agendamentoRealizado = false;
    this.scrollToEventDetails();
  }

  goToHome() {
    this.router.navigate(['home']);
    this.scrollTo(0);
  }

}
