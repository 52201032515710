import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ClientRootComponent} from './modules/client/client-root/client-root.component';
import {AdminRootComponent} from './modules/admin/admin-root/admin-root.component';
import { GetLoggedUserDataService } from './core/authenticate/get-logged-user-data.service';
import { HomeComponent } from './modules/client/home/home.component';
import { EventDatailsComponent } from './modules/client/event-datails/event-datails.component';
import { ListEventsService } from './modules/client/home/services/list-events.service';
import { LoginComponent } from './modules/admin/login/login.component';
// import { GetEventsDataService } from './modules/client/event-datails/services/get-events-data.service';

const routes: Routes = [


  /* Rotas LP */
  { path: '', redirectTo: '/home', pathMatch: 'full'  },
  {
    path: 'home',
    component: HomeComponent,
    resolve: {
      listEventsService: ListEventsService,
    },
  },
  {
    path: 'detalhes-evento/:id',
    component: EventDatailsComponent,
    resolve: {
      listEventsService: ListEventsService,
    },
  },

  /* Rotas Painel Admin */
  {
    path: 'admin/login',
    component: LoginComponent,
  },
  {
    path: 'admin',
    component: AdminRootComponent,
    resolve: {
      getLoggedUserDataService: GetLoggedUserDataService,
    },
    children: [
      {
        path: '',
        loadChildren: () =>
        import('./modules/admin/admin-routing.module').then(m => m.AdminRoutingModule),
      }
    ],
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
