<nav class="scdl-navbar transition-navbar"
fxLayout="row wrap" fxLayoutAlign="center center"
[ngClass]="{'expanded-navbar mat-elevation-z3': expandedNavbar, 'opened-menu': openedMenu}">

  <div class="scdl-navbar-container-logo" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center"
      class="scdl-navbar-logo-box">
        <img src="/assets/img/logo.png" alt="logo">
      </div>
      <div class="scdl-navbar-logo-text" fxLayoutAlign="center start">
        <h2>Guardiões da Luz Divina</h2>
      </div>
    </div>
    <div fxFlex fxLayoutAlign="end start"
    fxShow="true" fxShow.gt-sm="false" class="scdl-responsive-client-menu">
      <button mat-icon-button color="primary" aria-label="Menu"
      class="scdl-animated-btn-menu"
      (click)="menuControl()">
        <mat-icon *ngIf="!openedMenu">menu</mat-icon>
        <mat-icon *ngIf="openedMenu">close</mat-icon>
      </button>
    </div>
  </div>

  <div fxFlex fxLayout="row" class="scdl-contend-main-bar-width">
    <div fxFlex fxShow="{{!openedMenu}}"></div>
    <div fxFlex.xs fxFlex.sm
    fxShow="{{openedMenu}}" fxShow.gt-sm="true">
      <div *ngIf="isHomePage" class="scdl-client-menu" id="anchorMenu"
      fxLayout="column" fxLayout.gt-sm="row"
      fxLayoutAlign="center end" fxLayoutAlign.gt-sm="center center">
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="scdlHomePage" (click)="closeMenu()">Home</a>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="about" (click)="closeMenu()">Quem somos</a>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="moralValues" (click)="closeMenu()">Propósito</a>
        </div>
        <!-- <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="mission" (click)="closeMenu()">Missão</a>
        </div> -->
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="events" (click)="closeMenu()">Agendamento</a>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="contact" (click)="closeMenu()">Contato</a>
        </div>
      </div>
      <div *ngIf="!isHomePage" class="scdl-client-menu"
      fxLayout="column" fxLayout.gt-sm="row"
      fxLayoutAlign="center end" fxLayoutAlign.gt-sm="center center">
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="/home" (click)="closeMenu()">Home</a>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="/home#about" (click)="closeMenu()">Sobre nós</a>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="/home#moralValues" (click)="closeMenu()">Propósito</a>
        </div>
        <!-- <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="/home#mission" (click)="closeMenu()">Missão</a>
        </div> -->
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="/home#events" (click)="closeMenu()">Agendamento</a>
        </div>
        <div fxFlex.xs="100" fxFlex.sm="100" class="scdl-menu-btn">
          <a href="/home#contact" (click)="closeMenu()">Contato</a>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- <div *ngIf="expandedNavbar" id="scdl-up-button" class="scdl-up-button mat-elevation-z3" fxLayoutAlign="center center">
  <button (click)="gotoTop()" href="scdlHomePage">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
      <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
    </svg>
  </button>
</div> -->
