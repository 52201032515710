<div class="scdl-moral-values-section">
  <div class="decoration-border-after"></div>
  <div class="scdl-moral-values-container" fxLayout="row" fxLayoutAlign="center center" >
    <div fxFlex="100" class="scdl-content-width">
      <div class="scdl-moral-values-title">
        <h6 class="scdl-default-titles"><span class="scdl-primary-color">
          Propósito do Guardiões da Luz Divina
        </span></h6>
        <h2 class="scdl-default-titles">
          Ao cultuarmos nossos ancestrais, honramos não apenas suas vidas, mas também os valores que eles nos legaram e que iluminam nosso caminho
        </h2>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start start" style="padding-top: 24px;">
        <div class="scdl-moral-value-box" fxLayout="row" fxLayoutAlign="start start"
        *ngFor="let item of moralValuesList" fxFlex="50" fxFlex.gt-sm="33" fxFlex.xs="100">
          <!-- <h3 class="scdl-default-titles">{{item.title}}</h3> -->
          <!-- <span class="material-icons">check_circle</span> -->
          <span class="scdl-moral-value-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
            <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
          </svg></span>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="decoration-border-before"></div>
</div>
