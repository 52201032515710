<form [formGroup]="registerForm"  (ngSubmit)="saveScheduling()">
  <div mat-dialog-title class="scdl-dialog-title" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxFlex="" class="scdl-dialog-titleContainer">
      <h2 fxFlex="">Novo agendamento</h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography" fxLayout="row wrap"
  fxLayoutAlign="space-between center">
    <div class="scdl-form-box" fxFlex="100">
      <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
        <mat-label>Nome completo</mat-label>
        <input matInput required [pattern]="namePattern" formControlName="fullName"
            [(ngModel)]="registerData.fullName" name="fullName" type="text">
            <mat-error>Informe o nome e sobrenome</mat-error>
        <mat-hint>Informe o nome e sobrenome</mat-hint>
      </mat-form-field>
    </div>
    <div class="scdl-form-box" fxFlex="35" fxFlex.xs="100">
      <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
        <mat-label>Telefone</mat-label>
        <input matInput [required]="!eventIsStarted" [formControlName]="'cellPhone'"
        mask="{{registerData.cellPhone && this.registerData.cellPhone.length > 10 ? '(00) 00000-0009' : '(00) 0000-00009' }}"
        [(ngModel)]="registerData.cellPhone" name="cellPhone" type="text"
        [showMaskTyped]="false" [dropSpecialCharacters]="true">
        <mat-error>Telefone inválido</mat-error>
      </mat-form-field>
    </div>
    <div class="scdl-form-box" fxFlex="60" fxFlex.xs="100">
      <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
        <mat-label>E-mail</mat-label>
        <input matInput [pattern]="emailPattern" formControlName="email"
          [(ngModel)]="registerData.email" name="email" type="email">
        <mat-error>E-mail inválido</mat-error>
      </mat-form-field>
    </div>
    <!-- <div class="scdl-form-box" fxFlex="60" fxFlex.xs="100">
      <mat-slide-toggle color="primary"
      formControlName="priority"
      [(ngModel)]="registerData.priority">Atendimento prioritário</mat-slide-toggle>
    </div> -->
    <div class="scdl-form-box" fxFlex="100">
      <mat-form-field fxFlex="100" appearance="fill" [floatLabel]="'always'" >
        <mat-label>Observação</mat-label>
        <textarea matInput formControlName="observation"
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="5"
        maxlength="255"
          [(ngModel)]="registerData.observation" name="observation" type="observation">
        </textarea>
        <mat-hint align="end">
          {{registerData.observation && registerData.observation.length ? registerData.observation.length : 0}} / 255
        </mat-hint>
      </mat-form-field>
    </div>
    <!-- <div class="scdl-form-box" fxFlex="100" fxLayoutAlign="end center">
        <button mat-stroked-button type="reset"
        [disabled]="registerForm.untouched">Cancelar</button>
        <span style="width: 10px;"></span>
        <button mat-raised-button color="primary" type="submit"
        [disabled]="registerForm.invalid">Agendar</button>
    </div> -->
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button
    color="primary"
    [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>
    <button mat-raised-button color="primary" type="submit"
    [disabled]="registerForm.invalid">Agendar</button>
  </mat-dialog-actions>
</form>
