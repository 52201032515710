import {Component, Injectable, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class FeedbackMessagesComponent {
  durationInSeconds = 5;

  constructor(
    public snackBar: MatSnackBar
  ) {}

  showMessageError(data: any) {
    structureMessage.setData(data, 'Falhou');
    this.snackBar.openFromComponent(ScdlFeedbackMessagesComponent, {
      duration: 10000,
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
      panelClass: 'rb-alert-message-error',
    });
  }

  showMessageWarning(data: any) {
    structureMessage.setData(data, 'Atenção');
    this.snackBar.openFromComponent(ScdlFeedbackMessagesComponent, {
      duration: 10000,
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
      panelClass: 'rb-alert-message-warning'
    });
  }

  showMessageSuccess(data: any) {
    structureMessage.setData(data, 'Sucesso');
    this.snackBar.openFromComponent(ScdlFeedbackMessagesComponent, {
      duration: 10000,
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
      panelClass: 'rb-alert-message-success'
    });
  }

  showMessageInfo(data: any) {
    structureMessage.setData(data, 'Atenção');
    this.snackBar.openFromComponent(ScdlFeedbackMessagesComponent, {
      duration: 10000,
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
      panelClass: 'rb-alert-message-info'
    });
  }

  close(){
    this.snackBar.dismiss();
  }
}

@Component({
  selector: 'app-feedback-messages',
  templateUrl: './feedback-messages.component.html',
  styleUrls: ['./feedback-messages.component.scss']
})

export class ScdlFeedbackMessagesComponent  implements OnInit {

  public arrayNotice: object[] = [];
  public dataNotice: any;
  public titleNotice: any;

  constructor(
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.formatMessage(structureMessage.getData());
    this.titleNotice = structureMessage.getTitle();
  }

  getTypeOf(data: any) {
    return typeof data;
  }

  formatMessage(data: any) {
    if (data && typeof data === 'object') {
      for (let key in data) {
        this.arrayNotice.push(data[key]);
      }
      if (this.arrayNotice.length < 1 && data.length > 0)  {
        this.arrayNotice = data;
      }
    } else {
      this.dataNotice = data;
    }
  }

  close() {
    this.snackBar.dismiss();
  }


}

export class StructureMessage {
  public data: any;
  public title: any;

  setData(data: any, title: string) {
    this.data = data;
    this.title = title;
  }

  getData() {
    return this.data;
  }
  getTitle() {
    return this.title;
  }
}

const structureMessage = new StructureMessage();
