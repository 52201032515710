<div mat-dialog-title class="scdl-dialog-title" fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="" class="scdl-dialog-titleContainer"
  style="padding-right: 12px;">
    <h2 fxFlex="">Alterar status atendimento</h2>
    <button mat-icon-button aria-label="Interromper fila" [mat-dialog-close]="false"
      matTooltip="Interromper fila">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="details-person-scheduled-queue">
    <mat-label>Nome: </mat-label> <span>{{selectedPerson.fullName}}</span>
  </div>
  <div class="details-person-scheduled-queue">
    <mat-label>Posição na fila: </mat-label> <span>{{selectedPerson.position}}</span>
  </div>
  <div class="details-person-scheduled-queue" *ngIf="selectedPerson.priority">
    <mat-label>Atendimento prioritário: </mat-label> <span>{{selectedPerson.priority ? 'Sim' : 'Não'}}</span>
  </div>
  <!-- <div class="details-person-scheduled-queue">
    <mat-label>Código: </mat-label> <span>{{selectedPerson.securityCode}}</span>
  </div> -->
  <div class="details-person-scheduled-queue">
    <mat-label>Telefone: </mat-label> <span>{{selectedPerson.cellPhone}}</span>
  </div>
  <div *ngIf="selectedPerson.observation" class="details-person-scheduled-queue">
    <mat-label>Observação: </mat-label> <span>{{selectedPerson.observation}}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
class="person-scheduled-queue-actions">
  <button mat-stroked-button
  matTooltip="Alterar status da pessoa para aguardando"
  fxFlex.xs="100"
  (click)="updateStatus('WAITING')">Aguardando</button>
  <button mat-stroked-button
  matTooltip="Alterar status da pessoa para ausente ou cancelado"
  color="warn" fxFlex.xs="100"
  (click)="updateStatus('NOT_SERVICED')">Ausente</button>
  <button mat-stroked-button
  matTooltip="Alterar status da pessoa para atendido"
  color="primary" fxFlex.xs="100"
  (click)="updateStatus('SERVICED')">Atendido</button>
</mat-dialog-actions>
