<div class="scdl-events-section">
  <div fxLayout="row" fxLayoutAlign="center center" >
    <div fxFlex="100" class="scdl-content-width">
      <div class="scdl-title-box">
        <h2 class="scdl-default-titles scdl-titles scdl-gray-color">Agendamento</h2>
        <h6 class="scdl-default-titles scdl-titles scdl-primary-color">Veja mais informações sobre o programa e realize seu agendamento</h6>
      </div>
      <div class="available-sessions-box">
        <h3 class="scdl-default-titles scdl-titles scdl-gray-color">Agendas disponíveis </h3>

        <mat-accordion class="scdl-accordion scdl-sessions-card" *ngFor="let item of dataEvents.activedEvents; index as i" multi>
          <mat-expansion-panel class="scdl-expansion-panel scdl-sessions-status-bar actived-status" [expanded]="i==0">
            <mat-expansion-panel-header class="scdl-event-expansion-panel">
              <mat-panel-title fxLayout.xs="row wrap">
                <span style="min-width: fit-content;" class="current-event-title" title="{{item.type?.description}}"><b>{{item.type?.description}}</b></span> <span>&nbsp;|&nbsp;</span> <span class="current-event-title" title="{{item.name}}">{{item.name}}</span>
                <span style="width: 10px;"></span>
                <mat-panel-description style="min-width: 77px;">
                {{item.startDate | date : 'dd/MM/yyyy'}}
                </mat-panel-description>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="scdl-expansion-body" fxLayout="row wrap">
              <div fxFlex="100" fxLayoutAlign="start center">
                <p>{{item.description}}</p>
              </div>
            </div>
            <mat-action-row>
              <div fxFlex="100" fxLayoutAlign="end start">
                <button mat-raised-button color="primary" (click)="goToEventDatails(item.id)">Agendar</button>
              </div>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="!dataEvents.activedEvents.length">
          <label class="scdl-label-titles">Sem programação no momento</label>
        </div>
      </div>
      <hr class="available-sessions-divider">
      <div class="available-sessions-box next-sessions-box">
        <h3 class="scdl-default-titles scdl-titles scdl-gray-color">Próximas agendas</h3>

        <mat-accordion class="scdl-accordion scdl-sessions-card" *ngFor="let item of dataEvents.nextEvents; index as i" multi>
          <mat-expansion-panel class="scdl-expansion-panel scdl-sessions-status-bar" [expanded]="i==0">
            <mat-expansion-panel-header class="scdl-event-expansion-panel">
              <mat-panel-title fxLayout.xs="row wrap">
                <span style="min-width: fit-content;" class="current-event-title" title="{{item.type?.description}}"><b>{{item.type?.description}}</b></span> <span>&nbsp;|&nbsp;</span> <span class="current-event-title" title="{{item.name}}">{{item.name}}</span>
                <span style="width: 10px;"></span>
                <mat-panel-description style="min-width: 77px;">
                {{item.startDate | date : 'dd/MM/yyyy'}}
                </mat-panel-description>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="scdl-expansion-body" fxLayout="row wrap">
              <div fxFlex="100" fxLayoutAlign="start center">
                <p>{{item.description}}</p>
              </div>
            </div>
            <mat-action-row>
              <div fxFlex="100" fxLayoutAlign="end center">
                <p style="margin: 0px;"><i>A Liberação para o agendamento deve ocorrer em {{item.serviceStartDate | date : 'dd/MM/yyyy'}} às {{item.serviceStartDate | date : 'HH:mm'}}</i></p>
              </div>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="!dataEvents.nextEvents.length">
          <label class="scdl-label-titles">Sem programação no momento</label>
        </div>
      </div>
    </div>
  </div>
</div>
