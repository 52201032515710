<div class="scdl-admin-layout">
  <div class="scdl-admin-box-titles" fxLayout="row">
    <h2 class="scdl-admin-titles scdl-light-gray-color">Atendimento</h2>
  </div>
  <div class="scdl-admin-box-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-accordion *ngIf="currentEvents?.length"
    fxFlex="100" class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let event of currentEvents" hideToggle expanded>
        <mat-expansion-panel-header class="scdl-event-expansion-panel">
          <mat-panel-title fxLayout.xs="row wrap">
            <span><b>{{event.type?.description}}</b></span> <span fxHide.xs="true">&nbsp;|&nbsp;</span> <span class="current-event-title" title="{{event.name}}">{{event.name}}</span>
            <span fxHide.xs="true" style="width: 10px;"></span>
            <mat-panel-description style="min-width: 77px;">
             {{event.startDate | date : 'dd/MM/yyyy'}}
            </mat-panel-description>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="scdl-expansion-body">
          <!-- <div class="scdl-expansion-contents">
            {{event.description}}
          </div> -->
          <div class="scdl-expansion-contents">
            <button mat-button color="primary" fxLayout="row" fxLayoutAlign="center center"
            (click)="goToStartEvent(event)">
              Iniciar atendimento
              <mat-icon style="margin-bottom: 4px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play" viewBox="0 0 16 16">
                <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>
              </svg></mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="!currentEvents?.length"
    fxFlex="100">Nenhum evento disponível no momento</div>
  </div>
</div>
