import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public activeLoading = false;
  public controlLoaderChanges: any;

  constructor(
    public loaderService: LoaderService
  ) {
    this.controlLoaderChanges = this.loaderService.getChangesLoaderState()
    .subscribe(value => this.activeLoading = value);
  }

  ngOnInit() {
  }

}
