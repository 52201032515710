import { Component } from '@angular/core';

@Component({
  selector: 'app-client-root',
  templateUrl: './client-root.component.html',
  styleUrls: ['./client-root.component.scss']
})
export class ClientRootComponent {

}
